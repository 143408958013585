const validationsProfile = () => {

    const form_modify_profile = document.querySelectorAll('.form_modify_profile');
    if (!form_modify_profile || form_modify_profile.length === 0) return;

    // seleccionar el label con for="profile_image-clear_id"
    const profile_image_clear_id = document.querySelector('label[for="profile_image-clear_id"]');
    // Seleccionar el ultimo label con for="profile_image-clear_id"
    const profile_image_clear_id_last = document.querySelectorAll('label[for="profile_image-clear_id"]')[1];
    if (profile_image_clear_id) {

        //seleccionar for="id_profile_image" y luego tiene un texto que dice Actualmente: necesito eliminar ese texto
        const id_profile_image = document.querySelector('label[for="id_profile_image"]');

        //añadir un br al lado como hermano
        const br = document.createElement('br');
        id_profile_image.insertAdjacentElement('afterend', br);
        
        // seleccionar un item con name profile_image-clear
        const profile_image_clear = document.querySelector('input[name="profile_image-clear"]');
        //añadirle un br antes
        const br2 = document.createElement('br');
        profile_image_clear.insertAdjacentElement('beforebegin', br2);
        

        //seleccionar el ultimo con name profile_image-clear
        const profile_image_clear_last = document.querySelectorAll('input[name="profile_image-clear"]')[1];
        //añadirle un br antes
        profile_image_clear_last.insertAdjacentElement('beforebegin', br);



        //cambiarle el texto por Limpiar imagen
        profile_image_clear_id.innerHTML = 'Limpiar imagen';
        profile_image_clear_id_last.innerHTML = 'Limpiar imagen';
    }

    
    const btnSave = document.getElementById('btnSave');
    const modifyProfileBtnMobileSave = document.querySelector('.modifyProfileBtnMobileSave');
    const alert_container = document.querySelector('.alert_container');
    const alert_container_mobile = document.querySelector('.alert_container_mobile');
    const alert_message = document.getElementById('alert_message');
    const alert_message_mobile = document.getElementById('alert_message_mobile');

    let isProfileImageBig = false;
    let isWrongFormat = false;
    let isWrongEmail = false;


    if (form_modify_profile.length > 0) {
        // listener para el boton de modificar perfil
        btnSave.addEventListener('click', (e) => {

            const id_email_input = document.getElementById('id_email');
            const id_email_input_value = id_email_input.value;
            const id_email_input_value_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(id_email_input_value);

            if (isProfileImageBig) {
                e.preventDefault();
                alert_container.classList.remove('d-none');
                alert_message.innerHTML = 'La imagen no puede pesar mas de 2MB';
                return;
            }

            if (isWrongFormat) {
                e.preventDefault();
                alert_container.classList.remove('d-none');
                alert_message.innerHTML = 'La imagen debe ser formato JPG o PNG';
                return;
            }

            if (!id_email_input_value_regex) {
                e.preventDefault();
                alert_container.classList.remove('d-none');
                alert_message.innerHTML = 'El correo electronico no es valido';
                return;
            }

            //validar si algun input tipo text de form_modify_profile[0] esta vacio
            const inputs = Array.from(form_modify_profile[0].querySelectorAll('input[type="text"]'));
            //añadirle una restriccion de 40 caracteres
            inputs.forEach(input => {
                input.setAttribute('maxlength', '40');
            });

            const emptyInputs = inputs.filter(input => input.value === '');

            if (emptyInputs.length > 0) {
                e.preventDefault();
                // Limpiar los errores
                inputs.forEach(input => input.classList.remove('error'));
                // agrear clase error a los inputs vacios
                emptyInputs.forEach(input => input.classList.add('error'));
                alert_container.classList.remove('d-none');
                alert_message.innerHTML = 'Los campos marcados con * son obligatorios';
                return;
            } else {
                inputs.forEach(input => input.classList.remove('error'));

                //Regex letras y espacios
                const regex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
                const first_name_input = document.getElementById('id_first_name');
                const id_last_name_input = document.getElementById('id_last_name');
                const first_name_input_value = first_name_input.value;
                const id_last_name_input_value = id_last_name_input.value;
                const first_name_input_value_regex = regex.test(first_name_input_value);
                const id_last_name_input_value_regex = regex.test(id_last_name_input_value);



                if (!first_name_input_value_regex || !id_last_name_input_value_regex) {
                    e.preventDefault();
                    alert_container.classList.remove('d-none');
                    alert_message.innerHTML = 'El campo Nombre solo puede contener letras y espacios';
                    return;
                }

                if (!id_email_input_value_regex) {
                    e.preventDefault();
                    alert_container.classList.remove('d-none');
                    alert_message.innerHTML = 'El correo electronico no es valido';
                    isWrongEmail = true;
                    return;
                }

                const required_mark = document.querySelectorAll('.required_mark');
                required_mark.forEach(required => {
                    required.classList.add('d-none');
                });
                
                if (isProfileImageBig) {
                    e.preventDefault();
                    alert_container.classList.remove('d-none');
                    alert_message.innerHTML = 'La imagen no puede pesar mas de 2MB';
                    return;
                }

                if (isWrongFormat) {
                    e.preventDefault();
                    alert_container.classList.remove('d-none');
                    alert_message.innerHTML = 'La imagen debe ser formato JPG o PNG';
                    return;
                }

                e.preventDefault();

                Swal.fire({
                    title: '¿Estas seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0933f1',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, guardar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        form_modify_profile[0].submit();
                    }
                })

            }
        });
        

        // listener para el boton de modificar perfil mobile
        modifyProfileBtnMobileSave.addEventListener('click', (e) => {

            //validar si algun input tipo text de form_modify_profile[1] esta vacio
            const inputs = Array.from(form_modify_profile[1].querySelectorAll('input[type="text"]'));

            const emptyInputs = inputs.filter(input => input.value === '');
            if (emptyInputs.length > 0) {
                e.preventDefault();
                // Limpiar los errores
                inputs.forEach(input => input.classList.remove('error'));
                // agrear clase error a los inputs vacios
                emptyInputs.forEach(input => input.classList.add('error'));
                alert_container_mobile.classList.remove('d-none');
                alert_message_mobile.innerHTML = 'Los campos marcados con * son obligatorios';
                return;
            } else {

                inputs.forEach(input => input.classList.remove('error'));

                const form_container_inputs = document.querySelector('.form_container_inputs');

                //Regex letras y espacios
                const regex = /^[a-zA-ZÀ-ÿ\s]{1,40}$/;
                // seleccionar el input hijo de id first_name_input_mobile

                const first_name_input = form_container_inputs.querySelector('#id_first_name');
                const id_last_name_input = form_container_inputs.querySelector('#id_last_name');
                const email_input = form_container_inputs.querySelector('#id_email');
                const first_name_input_value = first_name_input.value;
                const id_last_name_input_value = id_last_name_input.value;
                const email_input_value = email_input.value;
                const first_name_input_value_regex = regex.test(first_name_input_value);
                const id_last_name_input_value_regex = regex.test(id_last_name_input_value);
                const email_input_value_regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email_input_value);

                if (!first_name_input_value_regex || !id_last_name_input_value_regex) {
                    e.preventDefault();
                    alert_container_mobile.classList.remove('d-none');
                    alert_message_mobile.innerHTML = 'El campo Nombre solo puede contener letras y espacios';
                    return;
                }

                // Validar si la imagen es mayor a 2MB
                if (isProfileImageBig) {
                    e.preventDefault();
                    alert_container.classList.remove('d-none');
                    alert_message.innerHTML = 'La imagen no puede pesar mas de 2MB';
                    return;
                }

                if (!email_input_value_regex) {
                    e.preventDefault();
                    alert_container_mobile.classList.remove('d-none');
                    alert_message_mobile.innerHTML = 'El correo electronico no es valido';
                    return;
                }

                // El formato de la imagen debe ser JPG o PNG
                if (isWrongFormat) {
                    e.preventDefault();
                    alert_container.classList.remove('d-none');
                    alert_message.innerHTML = 'La imagen debe ser formato JPG o PNG';
                    return;
                }

                e.preventDefault();

                Swal.fire({
                    title: '¿Estas seguro?',
                    text: "¡No podrás revertir esto!",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#0933f1',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Si, guardar!'
                }).then((result) => {
                    if (result.isConfirmed) {
                        form_modify_profile[0].submit();
                    }
                })
                

            }
        });


    }



    const profile_image_mobile = document.querySelector('.form_container_inputs');
        if (profile_image_mobile) {
            const id_profile_image = profile_image_mobile.querySelector('#id_profile_image');
            const alert_container_mobile = document.querySelector('.alert_container_mobile');
            const alert_message_mobile = document.getElementById('alert_message_mobile');

            validateImage(id_profile_image, 2097152, ['image/jpeg', 'image/png'], alert_container_mobile, alert_message_mobile);
        }

        const id_profile_image = document.getElementById('id_profile_image');
        if (id_profile_image) {
            const alert_container = document.querySelector('.alert_container');
            const alert_message = document.getElementById('alert_message');

            validateImage(id_profile_image, 2097152, ['image/jpeg', 'image/png'], alert_container, alert_message);
        }


    // Función para validar tamaño y formato de la imagen
        function validateImage(inputElement, maxSize, allowedFormats, alertContainer, alertMessage) {
            inputElement.addEventListener('change', (e) => {
                const file = e.target.files[0];

                if (!file) return;

                if (file.size > maxSize) {
                    e.preventDefault();
                    alertContainer.classList.remove('d-none');
                    alertMessage.innerHTML = 'La imagen no puede pesar más de ' + (maxSize / 1024 / 1024).toFixed(2) + 'MB';
                    isProfileImageBig = true;
                    return;
                }

                const file_type = file.type;

                if (!allowedFormats.includes(file_type)) {
                    e.preventDefault();
                    alertContainer.classList.remove('d-none');
                    alertMessage.innerHTML = 'La imagen debe ser formato JPG o PNG';
                    isWrongFormat = true;
                    return;
                }

                alertContainer.classList.add('d-none');
                isProfileImageBig = false;
                isWrongFormat = false;
            });
        }
}

export default validationsProfile;