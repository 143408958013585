import {
    alertError,
} from './helpers/swalAlerts.js';

const filterAforo = () => {

    

    //! REFACTORIZAR ESTE CÓDIGO

    let stringParam;

    const filter_ooh = document.querySelector('.filter_ooh'); 
    const filter_dooh = document.querySelector('.filter_dooh');
    const remove_filters = document.querySelector('#remove_filters');
    const clean_filters = document.querySelector('#clean_filters');
    // Si no estoy en OOH ni en DOOH, no ejecutar el código
    if (!filter_ooh && !filter_dooh) return;


    const filters_container = document.querySelector('.filters_container');
    const remove_filters_button = document.querySelector('#clean_filters');

    // !variables de inputs
    const name = document.querySelector('#id_name');
    const business_unit = document.querySelector('#id_business_unit');
    const station = document.querySelector('#id_station');
    const location = document.querySelector('#id_location');
    const element_type = document.querySelector('#id_element_type');
    const date_init = document.querySelector('#date_init');
    const date_end = document.querySelector('#date_end');
    const submit_filter = document.querySelector('#submit_filter');

    const status_container = document.querySelectorAll('.status_container');
    const add_spaces_btn = document.querySelectorAll('#add_spaces_btn');

    // !Obtener los valores de los query params
    const urlParams = new URLSearchParams(window.location.search);
    const name_url_param = urlParams.get('name');
    //console.log('name_url_param*****:: ', name_url_param);
    const business_unit_url_param = urlParams.get('business_unit');
    //console.log('business_unit_url_param*****: ', business_unit_url_param);
    const station_url_param = urlParams.get('station');
    
    const location_url_param = urlParams.get('location');
    //console.log('location_url_param*****:: ', location_url_param);
    const element_type_url_param = urlParams.get('element_type');
    //console.log('element_type_url_param*****:: ', element_type_url_param);
    const date_init_url_param = urlParams.get('date_init');
    //console.log('date_init_url_param*****:: ', date_init_url_param);
    const date_end_url_param = urlParams.get('date_end');
    //console.log('date_end_url_param*****:: ', date_end_url_param);


    if (filter_ooh) {
        stringParam = "ooh";
    }

    if (filter_dooh) {
        stringParam = "dooh";
    }


    //Desactivar el select de station
    station.disabled = true;
    //Desactivar el select de location
    location.disabled = true;
    //Desactivar el select de element_type
   //  element_type.disabled = true; //? Delete depending select



    const addDefaultOption = (selectElement, text = "---------", selected = false, clear = false) => {
        if (clear) {
            selectElement.innerHTML = "";
        }
        const defaultOption = document.createElement("option");
        defaultOption.value = "";
        defaultOption.selected = selected; // Agregar el atributo selected si es necesario
        defaultOption.textContent = text;
        selectElement.appendChild(defaultOption);
    };

    const fetchData = async (url, selectElement, querieParam) => {
        try {
            const response = await fetch(url);
            const data = await response.json();
    
            if (!querieParam) {
                selectElement.innerHTML = "";
                addDefaultOption(selectElement, "---------", false, false);
            } else {
                // Si existe el querie, deja el contenido del fetch y selecciona la opción del querie
                //console.log('Hay querie param :: ' + querieParam + " :: url: " + url + " :: selectElement: ", selectElement);
                selectElement.querySelectorAll("option:not([value=''])").forEach((option) => option.remove());
                data.locations.forEach((item) => {
                    const option = document.createElement("option");
                    option.value = item.pk;
                    option.innerText = item.name;
                    selectElement.appendChild(option);
                });
                

            }
        } catch (error) {
            console.error("Error al obtener y procesar los datos:", error);
        }
    };
    

    function toggleRemoveFiltersButton() {
        const filters = filters_container.querySelectorAll('.filter');
        const isFiltersActive = filters.length > 0;
        
        if (isFiltersActive) {
            remove_filters_button.classList.remove('d-none');
        } else {
            remove_filters_button.classList.add('d-none');
        }
    }

    function addFilter(filterClass, value) {
        const previousFilter = filters_container.querySelector(`.filter.${filterClass}`);
        if (previousFilter) {
            previousFilter.remove();
        }

        if (value && value.trim() !== '') {
            const filter = document.createElement('div');
            filter.classList.add('filter');
            filter.textContent = value;
            filter.classList.add(filterClass); // Agregar clase específica
            filters_container.appendChild(filter);
        }

        // Llamar a la función para mostrar u ocultar el botón "remove_filters"
        toggleRemoveFiltersButton();
    }


    // Funcionalidad para remover filtros
    remove_filters.addEventListener('click', (e) => {
        e.preventDefault();

        const modal = document.querySelector('.modal_filter');

        if (modal) {
            // obtener la url completa sin ningún query param
            const urlWithoutParams = window.location.href.split('?')[0];
            // redireccionar a la url
            window.location.href = urlWithoutParams;
        } else {
            const url = remove_filters.getAttribute('data-url');
            window.location.href = url;
        }
    });

    // Funcionalidad para limpiar filtros
    clean_filters.addEventListener('click', (e) => {
        e.preventDefault();
        //dar click al botón de remover filtros
        remove_filters.click();
    });


    const filtersBoxFunction = () => {
    
        //! Al cambiar name se añade el filtro
        name.addEventListener('input', () => {
            // Validar si filterName ya existe
            const previousFilter = filters_container.querySelector(`.filter.filter_name`);
            if (previousFilter) {
                previousFilter.remove();
            }
    
            // Crear el filtro de nombre
            const filterName = document.createElement('div');
            filterName.classList.add('filter', 'filter_name', 'd-none');
            filters_container.appendChild(filterName);
    
            const inputValue = name.value;
            filterName.textContent = "Nombre";
    
            if (inputValue.trim() === '') {
                filterName.classList.add('d-none');
            } else {
                filterName.classList.remove('d-none');
            }
        });
        
    
        //! Al cambiar business_unit se añade el filtro
        business_unit.addEventListener('change', () => {
            addFilter('business_unit', "Unidad de negocio");
            // Activar el select de station
            station.disabled = false;
        });
    
        //! Al cambiar station se añade el filtro
        station.addEventListener('change', () => {
            addFilter('station', "Localización");
            // Activar el select de location
            location.disabled = false;
            // Activar el select de element_type
            element_type.disabled = false;
        });
    
        //! Al cambiar location se añade el filtro
        location.addEventListener('change', () => {
            addFilter('location', "Ubicación");
        });
    
        //! Al cambiar element_type se añade el filtro
        element_type.addEventListener('change', () => {
            addFilter('element_type', "Tipo de elemento");
        });

        //! Al cambiar date_init se añade el filtro
        date_init?.addEventListener('change', () => {
            addFilter('date_init', "Fecha inicial");
        });

        //! Al cambiar date_end se añade el filtro
        date_end?.addEventListener('change', () => {
            addFilter('date_end', "Fecha final");
        });
    
    }


    const queryParamsFilterBox = async () => {
        // Mostrar los filtros si hay parámetros de consulta en la URL

       

        if (name_url_param) {
            const filterName = document.createElement('div');
            filterName.classList.add('filter', 'filter_name');
            filterName.textContent = "Nombre";
            filters_container.appendChild(filterName);
        } else {
            // Si no hay parámetros de consulta, limpiar el select de business_unit
            
        }
    
        if (business_unit_url_param) {
            // console.log('business_unit_url_param: ', business_unit_url_param);
            const filterBusinessUnit = document.createElement('div');
            filterBusinessUnit.classList.add('filter', 'filter_business_unit');
            filterBusinessUnit.textContent = "Unidad de negocio"
            filters_container.appendChild(filterBusinessUnit);

            // Habilitar el select de station
            station.disabled = false;

            // Hacer fetch para obtener las estaciones
            const id_bu = business_unit.value;
            await fetchData(`${window.location.origin}/aforo/get_station_list_by_business_unit/${id_bu}/${stringParam}`, station, business_unit_url_param);
            
        } else {
            // Si no hay parámetros de consulta, limpiar el select de station}
            addDefaultOption(station, "---------", true, true);
        }
         
    
        if (station_url_param) {
            const filterStation = document.createElement('div');
            filterStation.classList.add('filter', 'filter_station');
            filterStation.textContent = "Localización";
            filters_container.appendChild(filterStation);

            // Habilitar el select de location
            location.disabled = false;
            // Habilitar el select de element_type
            element_type.disabled = false;

            // Hacer fetch para obtener las ubicaciones
            await fetchData(`${window.location.origin}/aforo/get_location_list_by_station/${station_url_param}/`, location, station_url_param);
            await fetchData(`${window.location.origin}/aforo/get-type-element-by-station/${station_url_param}/`, element_type, station_url_param);

            // Setear el valor del select de station
            station.value = station_url_param;
        } else {
            // Si no hay parámetros de consulta, limpiar el select de location
            addDefaultOption(location, "---------", true, true);
        }

    
        if (location_url_param) {
            const filterLocation = document.createElement('div');
            filterLocation.classList.add('filter', 'filter_location');
            filterLocation.textContent = "Ubicación"
            filters_container.appendChild(filterLocation);

            // Setear el valor del select de location
            location.value = location_url_param;
        } else {
            
            // Si no hay parámetros de consulta, limpiar el select de element_type
            // addDefaultOption(element_type, "---------", true, true); //? Delete depending select
            // desactivar el select de element_type
        }
    
        if (element_type_url_param) {
            const filterElementType = document.createElement('div');
            filterElementType.classList.add('filter', 'filter_element_type');
            filterElementType.textContent = "Tipo de elemento";
            filters_container.appendChild(filterElementType);

            // Setear el valor del select de element_type
            element_type.value = element_type_url_param;
        } 

        if (date_init_url_param) {
            const filterDateInit = document.createElement('div');
            filterDateInit.classList.add('filter', 'filter_date_init');
            filterDateInit.textContent = "Fecha inicial";
            filters_container.appendChild(filterDateInit);
        }

        if (date_end_url_param) {
            const filterDateEnd = document.createElement('div');
            filterDateEnd.classList.add('filter', 'filter_date_end');
            filterDateEnd.textContent = "Fecha final";
            filters_container.appendChild(filterDateEnd);
        }

        // Si existe date_init_url_param y date_end_url_param, quitar d-none de status_container y add_spaces_btn
        if (date_init_url_param && date_end_url_param) {

            function obtenerFechaHoy() {
                const hoy = new Date();
                const anio = hoy.getFullYear();
                const mes = String(hoy.getMonth() + 1).padStart(2, '0');
                const dia = String(hoy.getDate()).padStart(2, '0');
                
                return `${anio}-${mes}-${dia}`;
              }


            // Setear los valores de los inputs
            date_init.value = date_init_url_param;
            date_end.value = date_end_url_param;
            // console.log('date_init.value: ', date_init.value);

            const dateNow = obtenerFechaHoy();
            // console.log('dateNow: ', dateNow);

            // Comparar las fechas
            if (date_init_url_param > dateNow) {
                // Realizar acciones correspondientes
                status_container.forEach((status) => {
                    status.classList.remove('d-none');
                });
                add_spaces_btn.forEach((btn) => {
                    btn.classList.remove('d-none');
                });
            } else {
                // Realizar otras acciones correspondientes
                alertError('Alerta!', "Para poder añadir espacios, la fecha inicial debe ser posterior a la de hoy.", 5000);
            }
            

           

            
        }

        
    }

    const validateSubmit = () => {
        if (!submit_filter) return;
        submit_filter.addEventListener('click', (e) => {
            // Validar si date_init o date_end tienen valor
            if ((date_init.value.trim() === '' && date_end.value.trim() !== '') || (date_init.value.trim() !== '' && date_end.value.trim() === '')) {
                e.preventDefault();
                alertError('Si especificas una fecha inicial o final, asegúrate de completar ambas.');
            }

            // Validar que date_init sea menor que date_end
            if (date_init.value.trim() !== '' && date_end.value.trim() !== '') {
                const dateInit = new Date(date_init.value);
                const dateEnd = new Date(date_end.value);

                if (dateInit > dateEnd) {
                    e.preventDefault();
                    alertError('La fecha inicial debe ser anterior que la fecha final.');
                }
            }

        });
    };


    filtersBoxFunction();
    queryParamsFilterBox();
    toggleRemoveFiltersButton();
    validateSubmit();

};

export default filterAforo;
