const convertToSelectOptions = (array, pkKey, nameKey) => {
    return array.map((item) => ({
        value: item[pkKey],
        text: item[nameKey],
    }));
};


const populateSelect = (selectElement, options) => {
    // Limpiar el select
    selectElement.innerHTML = '';

    // Agregar la opción por defecto con value vacío
    const defaultOption = document.createElement('option');
    defaultOption.value = '';
    defaultOption.text = '----------';
    selectElement.appendChild(defaultOption);

    options?.forEach((option) => {
        const optionElement = document.createElement('option');
        optionElement.value = option.value;
        optionElement.text = option.text;
        selectElement.appendChild(optionElement);
    });
};

export { populateSelect, convertToSelectOptions };