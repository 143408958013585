const bookingInstallFunctions = () => {
    const form_evidences_uninstalled = document.querySelector('#form_evidences_uninstalled');
    if (!form_evidences_uninstalled) return;
  
    // Seleccionar todos los checkbox que inicien con el name diurnal
    const checkboxes = document.querySelectorAll('input[name^="diurnal"]');
    // console.log(checkboxes);
  
    // Verificar si todos los checkboxes están marcados
    const allChecked = [...checkboxes].every(checkbox => checkbox.checked);
  
    // Si todos los checks con name diurnal están checked, entonces quitar el d-none del form
    if (allChecked) {
      form_evidences_uninstalled.classList.remove('d-none');
    }
  }

    export default bookingInstallFunctions;