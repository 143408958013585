import {
    alertConfirm,
    alertError,
    alertSuccess,
    alertHtml,
} from './helpers/swalAlerts.js';
import {
    addErrorClasses,
    clearErrorClasses,
} from './helpers/validateInputsColor.js';

const bookingDetails = () => {
    const step2 = document.getElementById('step2');
    if (!step2) return;

    const add_spaces = document.querySelector('.add_spaces');

    // Validar si hay query strings en la URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    // Verificar si algún query string tiene algún dato
    let hasData = false;
    for (const [key, value] of urlParams) {
        if (value) {
            hasData = true;
            break; // Si encuentra al menos un query string con dato, puedes detener el bucle
        }
    }

    if (hasData) {
        // Hay al menos un query string con dato
        // Dar click al botón "Agregar espacios"
        add_spaces.click();
    } else {
        // No hay ningún query string con dato
    }

    // Botones add spaces sweeet alert
    const add_spaces_btn = document.querySelectorAll('#add_spaces_btn');
    if (!add_spaces_btn) return;

    add_spaces_btn.forEach((btn) => {
        // Añadir el estilo de cursor pointer
        btn.style.cursor = 'pointer';

        btn.addEventListener('click', (e) => {
            e.preventDefault();

            const url = btn.getAttribute('data-url');
            const data_item_name = btn.getAttribute('data-item-name');
            const data_item_location = btn.getAttribute('data-item-location');
            const data_item_station = btn.getAttribute('data-item-station');
            const data_ocuppated_name = btn.getAttribute('data-ocuppated-name');
            const data_ocuppated_initial_date = btn.getAttribute(
                'data-ocuppated-initial-date'
            );
            const data_ocuppated_finish_date = btn.getAttribute(
                'data-ocuppated-finish-date'
            );

            if (
                data_ocuppated_name !== 'None' &&
                data_ocuppated_initial_date !== 'None' &&
                data_ocuppated_finish_date !== 'None'
            ) {
                alertHtml(
                    '¡Este espacio ya está ocupado!',
                    ` <b>Este espacio ya está ocupado:</b><br> <b>Nombre:</b> ${data_ocuppated_name}<br> <b>Fecha inicial:</b> ${data_ocuppated_initial_date}<br> <b>Fecha final:</b> ${data_ocuppated_finish_date}<br><br> <b>Si aceptas, el siguiente espacio se agregará a la cola de reserva:</b><br> <b>Nombre:</b> ${data_item_name}<br> <b>Ubicación:</b> ${data_item_location}<br> <b>Localización:</b> ${data_item_station}<br> `,
                    'Agregar a la cola de reserva!',
                    'Cancelar'
                ).then((result) => {
                    // redireccionar a la url
                    if (result.isConfirmed) {
                        window.location.href = url;
                    }
                });
            } else {
                alertHtml(
                    '¿Estás seguro?',
                    ` <b>Estás a punto de agregar este espacio.</b><br> <b>Nombre:</b> ${data_item_name}<br> <b>Ubicación:</b> ${data_item_location}<br> <b>Localización:</b> ${data_item_station}<br> `,
                    'Agregar espacio!',
                    'Cancelar'
                ).then((result) => {
                    // redireccionar a la url
                    if (result.isConfirmed) {
                        window.location.href = url;
                    }
                });
            }
        });
    });

    const delete_spaces_btn = document.querySelectorAll('#delete_spaces_btn');
    if (!delete_spaces_btn) return;

    delete_spaces_btn.forEach((btn) => {
        // Añadir el estilo de cursor pointer
        btn.style.cursor = 'pointer';

        btn.addEventListener('click', (e) => {
            e.preventDefault();

            const url = btn.getAttribute('data-url');
            alertConfirm(
                '¿Estás seguro?',
                '¿Deseas eliminar este espacio de la reserva?',
                'Sí, eliminar espacio!',
                'Cancelar'
            ).then((result) => {
                // redireccionar a la url
                if (result.isConfirmed) {
                    window.location.href = url;
                }
            });
        });
    });

    const delete_booking_btn = document.getElementById('delete_booking_btn');
    if (delete_booking_btn) {
        delete_booking_btn.addEventListener('click', (e) => {
            e.preventDefault();

            alertConfirm(
                '¿Estás seguro?',
                '¿Deseas eliminar esta reserva?',
                'Sí, eliminar reserva!',
                'Cancelar'
            ).then((result) => {
                // redireccionar a la url
                if (result.isConfirmed) {
                    delete_booking_btn.parentElement.submit();
                }
            });
        });
    }

    const finish_booking_btn = document.getElementById('finish_booking_btn');
    if (finish_booking_btn) {
        const url = finish_booking_btn.getAttribute('data-url');
        const data_item_name =
            finish_booking_btn.getAttribute('data-item-name');

        // Añadir el estilo de cursor pointer
        finish_booking_btn.style.cursor = 'pointer';

        finish_booking_btn.addEventListener('click', (e) => {
            e.preventDefault();

            const accordion_item_spaces = document.querySelectorAll(
                '.accordion_item_spaces'
            );

            if (accordion_item_spaces.length > 0) {
                alertConfirm(
                    '¿Estás seguro?',
                    `Estas a punto de finalizar ${data_item_name}.`,
                    'Sí, cerrar reserva!',
                    'Cancelar'
                ).then(async (result) => {
                    // redireccionar a la url
                    if (result.isConfirmed) {
                        // Ocultar el botón de finalizar reserva
                        finish_booking_btn.style.display = 'none !important';

                        // Redirección
                        window.location.href = url;

                        // Esperar un breve momento antes de mostrar el SweetAlert
                        await new Promise((resolve) =>
                            setTimeout(resolve, 100)
                        );
                        alertSuccess(
                            '¡Reserva finalizada!',
                            'La reserva se ha finalizado correctamente',
                            7000,
                            false,
                            false
                        );
                    }
                });
            } else {
                alertError(
                    '¡Error!',
                    'Debes agregar al menos un espacio',
                    3000,
                    false,
                    true
                );
            }
        });
    }

    const aproved_btn = document.getElementById('aproved_btn');
    if (aproved_btn) {
        aproved_btn.addEventListener('click', (e) => {
            e.preventDefault();

            alertConfirm(
                '¿Estás seguro?',
                '¿Deseas confirmar esta reserva?',
                'Sí, confirmar reserva!',
                'Cancelar'
            ).then((result) => {
                // redireccionar a la url
                if (result.isConfirmed) {
                    alertSuccess(
                        '¡Reserva confirmada!',
                        'La reserva se ha confirmado correctamente',
                        7000,
                        false,
                        false
                    );
                    aproved_btn.parentElement.submit();
                }
            });
        });
    }

    const aproved_art_btn = document.getElementById('aproved_art_btn');
    if (aproved_art_btn) {
        aproved_art_btn.addEventListener('click', (e) => {
            e.preventDefault();

            // Tomar el id del input id_url_artes
            const id_url_artes = document.getElementById('id_url_artes');
            clearErrorClasses([id_url_artes]);

            if (!addErrorClasses(id_url_artes)) {
                alertError(
                    '¡Error!',
                    'Debes agregar una URL de artes',
                    3000,
                    false,
                    true
                );
                return;
            }

            alertHtml(
                '¿Estás seguro?',
                `<b>Estas a punto de agregar la siguiente URL de artes:</b> <br> ${id_url_artes.value}<br> `,
                'Sí, agregar URL!',
                'Cancelar'
            ).then((result) => {
                // redireccionar a la url
                if (result.isConfirmed) {
                    alertSuccess(
                        '¡URL de artes agregada!',
                        'La URL de artes se ha agregado correctamente',
                        7000,
                        false,
                        false
                    );
                    aproved_art_btn.parentElement.submit();
                }
            });
        });
    }
};

export default bookingDetails;
