
const subMenuBtn = () => {

    const menu = document.querySelector('.menu');
    if (!menu) return;

    
    const aforos_submenu = document.getElementById('aforos_submenu');
    const feather_chevron_left = document.querySelector('.feather-chevron-left');
    const submenu = document.querySelector('.submenu');
    const menu_sup = document.querySelector('.menu_sup');

    
    if (aforos_submenu && feather_chevron_left && submenu && menu_sup) {
        aforos_submenu.addEventListener('click', () => {
            submenu.classList.remove('d-none');
    
            // ocultar menu_sup
            menu_sup.classList.add('d-none');
        });
    
        //Al eventi click validar si el mobileBtn tiene la clase back
        feather_chevron_left.addEventListener('click', () => {
            submenu.classList.add('d-none');
    
            // mostrar menu_sup
            menu_sup.classList.remove('d-none');
        });
    }
 
  }
  
  
  export default subMenuBtn;