import { fetchData } from './helpers/fetchData';
import { alertConfirm, alertGeneric } from './helpers/swalAlerts';

const bonusBookingFunctions = () => {
    const content_bonus = document.querySelector('.content_bonus');
    if (!content_bonus) return;

    // Función para cambiar el color de fondo del bonus_container según el estado del checkbox
    const updateBonusContainerColor = (checkbox) => {
        const bonus_container = checkbox.parentElement;
        if (checkbox.checked) {
            bonus_container.style.backgroundColor = '#ccdfff';
        } else {
            bonus_container.style.backgroundColor = '';
        }
    };

    // Aplica el color inicial a los checkboxes seleccionados cuando la página carga
    const applyInitialStyles = () => {
        const checkboxes = content_bonus.querySelectorAll( 'input[type="checkbox"]' );
        checkboxes.forEach((checkbox) => {
            updateBonusContainerColor(checkbox); // Aplica el color inicial
        });
    };

    // Cambia el color cuando los checkboxes cambian de estado
    const changeColorOnCheckboxChange = () => {
        const checkboxes = content_bonus.querySelectorAll( 'input[type="checkbox"]' );
        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', function () {
                updateBonusContainerColor(this); // Cambia el color cuando cambia el estado
            });
        });
    };

    // Lógica para preguntar y confirmar si añadir o quitar bonificación
    const callServiceCheckbox = () => {
        const checkboxes = content_bonus.querySelectorAll('input[type="checkbox"]');

        checkboxes.forEach((checkbox) => {
            checkbox.addEventListener('change', async function () {
                const bonus_container = this.parentElement;
                const data_ubicacion = bonus_container.getAttribute('data-type-booking');
                const id_booking = this.id;
                // separar el id por el _ y seleccionar el segundo elemento

                // console.log('Ubicación:', data_ubicacion);

                if (this.checked) {
                    const result = await alertConfirm( '¿Estás seguro?', '¿Deseas añadir bonificación a la reserva?', 'Sí, añadir bonificación!', 'Cancelar' );

                    if (result.isConfirmed) {
                        try {
                            const res = await fetchData(`${window.location.origin}/aforo/put-bonus/${data_ubicacion}/${id_booking}`);
                            // console.log('Bonificación añadida:', res);
                            if (res.status === true) {
                                this.checked = true;
                                await alertGeneric('Bonificación añadida', 'La bonificación se ha añadido correctamente.', 2000, false, false, 'success');
                            }
                        } catch (error) {
                            console.error('Error añadiendo bonificación:', error);
                            this.checked = false;
                            await alertGeneric('Error', 'Ha ocurrido un error añadiendo la bonificación.', 2000, false, false, 'error');
                        }
                    } else {
                        this.checked = false; // Si se cancela, desmarcar checkbox
                        updateBonusContainerColor(this); // Actualizar color
                    }
                } else {
                    const result = await alertConfirm( '¿Estás seguro?', '¿Deseas remover la bonificación de la reserva?', 'Sí, remover bonificación!', 'Cancelar' );

                    if (result.isConfirmed) {
                        try {
                            const res = await fetchData(`${window.location.origin}/aforo/put-bonus/${data_ubicacion}/${id_booking}`);
                            // console.log('Bonificación removida:', res);
                            if (res.status === false) {
                                this.checked = false;
                                await alertGeneric('Bonificación removida', 'La bonificación se ha removido correctamente.', 2000, false, false, 'success');
                            }
                        } catch (error) {
                            console.error('Error removiendo bonificación:', error);
                            this.checked = true;
                        }
                    } else {
                        this.checked = true; // Si se cancela, marcar checkbox
                        updateBonusContainerColor(this); // Actualizar color
                    }
                }
            });
        });
    };

    // Ejecutar las funciones necesarias al cargar la página
    applyInitialStyles();       // Aplicar colores iniciales
    changeColorOnCheckboxChange(); // Cambiar colores cuando se seleccionan/desmarcan
    callServiceCheckbox();      // Confirmar bonificaciones
};

export default bonusBookingFunctions;