const dotsBtn = () => {
    const dotsContainers = document.querySelectorAll('.dots-container');
    
    if (dotsContainers.length > 0) {
        dotsContainers.forEach((dotsContainer) => {
            dotsContainer.addEventListener('click', function () {
                const popoverContainerID = dotsContainer.getAttribute('data-target');
    
                if (popoverContainerID) {
                    const popoverContainer = document.getElementById(popoverContainerID);
    
                // Ocultar los otros popoverContainer
                document.querySelectorAll('.popover_container').forEach((popover) => {
                    if (popover !== popoverContainer) {
                        popover.classList.add('d-none');
                    }
                });
                
                // Mostrar el popoverContainer actual
                popoverContainer.classList.remove('d-none');
                }
                
            });
        });

        // Resto de tu lógica aquí...
        const closeIcons = document.querySelectorAll('.close-icon');
        closeIcons.forEach((closeIcon) => {
            closeIcon.addEventListener('click', function () {
                const popoverContainerID = closeIcon.getAttribute('data-target');

                if (popoverContainerID) {
                    const popoverContainer = document.getElementById(popoverContainerID);
                    popoverContainer.classList.add('d-none');
                }
            });
        });
    }
};

export default dotsBtn;