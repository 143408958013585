import { alertError, alertHtml } from './helpers/swalAlerts.js';
import {
    clearErrorClasses,
    addErrorClasses,
} from './helpers/validateInputsColor.js';

const modifyBooking = () => {
    const modify_booking_form = document.querySelector('#modify_booking_form');
    if (!modify_booking_form) return;

    const name_info = document.querySelector('#name_info');
    const date_info = document.querySelector('#date_info');
    const name_input = document.querySelector('#name_input');
    const date_input = document.querySelector('#date_input');

    const modify_booking_btn = document.querySelector('#modify_booking_btn');
    const save_modify_booking_btn = document.querySelector(
        '#save_modify_booking_btn'
    );

    if (!save_modify_booking_btn) return;

    const actual_value_date = document.querySelector('#initial-id_finish_date');

    modify_booking_btn.addEventListener('click', (e) => {
        e.preventDefault();

        const date_input_ = date_input.querySelector('#id_finish_date');
        date_input_.value = actual_value_date.value;

        // Setear el min del input date con el date_input_.value + 1 que viene en formato 2023-10-26
        const date_input_min = (date_input.querySelector(
            '#id_finish_date'
        ).min = actual_value_date.value);

        name_info.classList.add('d-none');
        date_info.classList.add('d-none');

        name_input.classList.remove('d-none');
        date_input.classList.remove('d-none');

        modify_booking_btn.classList.add('d-none');
        save_modify_booking_btn.classList.remove('d-none');
    });

    save_modify_booking_btn.addEventListener('click', (e) => {
        e.preventDefault();

        const name_input_ = name_input.querySelector('#id_name');
        const date_input_ = date_input.querySelector('#id_finish_date');

        //Seleccionar el id_name dentro de #name_input
        const name_value = name_input_.value;
        const date_value = date_input_.value;

        // Remover clases de error
        clearErrorClasses([name_input_, date_input_]);

        if (name_value === '' || date_value === '') {
            addErrorClasses(name_input_, date_input_);
            alertError('¡Error!', 'Debes llenar todos los campos', 2000, false);

            return;
        } else {
            alertHtml(
                '¿Estás seguro?',
                `Estás a punto de modificar la reserva: <br> <b>Nombre:</b> ${name_value} <br> <b>Fecha Finalización:</b> ${date_value}`,
                'Si, modificar',
                'Cancelar'
            ).then((result) => {
                // redireccionar a la url
                if (result.isConfirmed) {
                    modify_booking_form.submit();
                }
            });
        }
    });
};

export default modifyBooking;
