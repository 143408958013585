import {
    alertError,
    alertConfirm,
    alertSuccess,
} from './helpers/swalAlerts.js';

const dinamyc_message = (form, button, input, text) => {
    const formElement = document.querySelector(form);
    if (!formElement) return;

    const inputElement = formElement.querySelector(input);
    if (!inputElement) return;

    const buttonElement = formElement.querySelector(button);
    if (!buttonElement) return;

    const handleButtonClick = (e) => {
        e.preventDefault();

        // Remover clases de error
        inputElement.classList.remove('border-danger');

        // Validar que el campo de texto no esté vacío
        if (inputElement.value === '') {
            inputElement.classList.add('border-danger');
            alertError(
                '¡Error!',
                'Debes llenar el campo correspondiente',
                2000,
                false,
                false
            );

            return;
        }

        // Realizar el proceso de confirmación y envío
        alertConfirm(
            '¡Atención!',
            `Enviar ${text}, ¿estás seguro?`,
            'Sí, enviar!',
            'Cancelar'
        ).then((result) => {
            if (result.isConfirmed) {
                formElement.submit();
                alertSuccess(
                    '¡Acción exitosa!',
                    `${text} enviado correctamentex`,
                    4000,
                    false,
                    false
                );
            }
        });
    };

    // Agregar evento de clic al botón
    buttonElement.addEventListener('click', handleButtonClick);

    // Agregar evento de clic al input para evitar la propagación
    inputElement.addEventListener('click', (e) => {
        e.stopPropagation();
    });
};

const bookingVerify = () => {
    const id_feedback_arts = document.getElementById('id_feedback_arts');
    if (!id_feedback_arts) return;

    // console.log('bookingVerify.js loaded successfully');


    dinamyc_message(
        '#form_feedback_arts',
        '#submit_feedback_arts',
        '#id_feedback_arts',
        'feedback artes'
    );
    dinamyc_message(
        '#form_feedback_installed',
        '#submit_feedback_installed',
        '#id_feedback_installed',
        'feedback instalador'
    );
};

export default bookingVerify;
