import { clearErrorClasses, addErrorClasses } from './helpers/validateInputsColor.js';

const validationLoginForm  = () => {

    // Validaciones para el formulario de login
    const loginForm = document.getElementById('login-form');
    if (!loginForm) return;

    const loginBtn = document.getElementById('submit-id-login');
    const idUsername = document.getElementById('id_username');
    const idPassword = document.getElementById('id_password');
    const errorLoginText = document.getElementById('error_login_text');

    // crear un div 
    const divEye = document.createElement('div');
    divEye.classList.add('eye');

    const eyeOff = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye-off"><path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path><line x1="1" y1="1" x2="23" y2="23"></line></svg>'

    const eyeOn = '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-eye"><path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path><circle cx="12" cy="12" r="3"></circle></svg>'

    divEye.innerHTML = eyeOn;
    // Isertar al lado de idPassword
    idPassword.parentNode.insertBefore(divEye, idPassword.nextSibling);

    // Agregar evento click al div
    divEye.addEventListener('click', () => {
        if (idPassword.type === 'password') {
            idPassword.type = 'text';
            divEye.innerHTML = eyeOff;
        } else {
            idPassword.type = 'password';
            divEye.innerHTML = eyeOn;
        }
    });


    loginBtn.addEventListener('click', (e) => {
 
        const username = idUsername.value;
        const password = idPassword.value;

        clearErrorClasses([idUsername, idPassword]);

        let errorMessage = '';
    
        switch (true) {
            case username === '':
                e.preventDefault();
                // idUsername.classList.add('input_error');
                addErrorClasses(idUsername);
                errorMessage = 'El campo usuario es obligatorio';
                break;
            case username.length < 4:
                e.preventDefault();
                idUsername.classList.add('border-danger');
                errorMessage = 'El campo usuario debe tener al menos 4 caracteres';
                break;
            case password === '':
                e.preventDefault(); 
                // idPassword.classList.add('input_error');
                addErrorClasses(idPassword);
                errorMessage = 'El campo contraseña es obligatorio';
                break;
            // Agrega más casos aquí si es necesario

            default:
                // Limpiar clases y mensajes de error en caso de éxito
                clearErrorClasses([idUsername, idPassword]);
                break;
        }
    
        errorLoginText.innerHTML = errorMessage;
    });

}

export default validationLoginForm;