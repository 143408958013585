import {
    alertConfirm,
    alertError,
    alertSuccess,
    alertHtml,
    simpleAlert,
    alertGeneric
} from './helpers/swalAlerts.js';
import {  fetchData } from './helpers/fetchData.js';

const stepOnePreselectionFunctions = () => {
    let ubication = ""

    const delete_preselection_btn = document.querySelectorAll(
        '#delete_preselection_btn'
    );
    const step_2_ooh = document.querySelector("#step_2_ooh")
    const step_2_dooh = document.querySelector("#step_2_dooh")
    const delete_all_preselection = document.querySelector("#delete_all_preselection")

    
    if (step_2_ooh) {
        ubication = "ooh"
    } else if (step_2_dooh) {
        ubication = "dooh"
    } else {
        return
    }

    if (delete_preselection_btn.length > 0) {

        delete_preselection_btn.forEach((btn) => {
            // Darle estilo cursor pointer al botón
            btn.style.cursor = 'pointer';

            btn.addEventListener('click', async (e) => {
                /* path('delete-station-temp/<str:service>/<int:id>', views.delete_station_temp_id, name='delete_station_temp_id'), */
                e.preventDefault();
                const id = btn.getAttribute('data-id');
                const name = btn.getAttribute('data-name');
                const url = ubication === "ooh" ? `${window.location.origin}/aforo/delete-station-temp/${ubication}/${id}` : `${window.location.origin}/aforo/delete-station-temp/${ubication}/${id}`;

                // const title = ;
                // const text = `Si eliminas la preselección ${name} no podrás recuperarla.`;

                try {
                    const resultConfirm = await alertConfirm(
                        '¿Estás seguro?',
                        `Estás a punto de eliminar la pre-selección ${name}.`,
                        'Si, eliminar',
                        'Cancelar'
                    );
    
                    if (resultConfirm.isConfirmed) {
                        const result = await fetchData(url);
                        // console.log('result: ', result);
    
                        if (result.status) {
                            alertSuccess(
                                'Accion realizada',
                                `${result?.status}`
                            );
                            setTimeout(() => {
                                location.reload();
                            }, 2000);

                        }  else {
                            alertError(
                                'Error',
                                `${result?.error}`
                            );
                        }
                    }
                } catch (error) {
                    alertError("Error", "No se pudo eliminar la preselección")
                }
                

                    
            });
        });

    }

    if (delete_all_preselection) {

        delete_all_preselection.addEventListener('click', async (e) => {
            e.preventDefault();
    
                    // Preguntar si desea eliminar los aforos
                    const resultConfirm = await alertConfirm(
                        '¿Estás seguro?',
                        'Si aceptas, se eliminarán todos los aforos pre-seleccionados.',
                        'Eliminar pre-selección',
                        'Cancelar'
                    );
    
                    // Si está confirmado
                    if (resultConfirm.isConfirmed) {
                        let srvstring = ubication === 'ooh' ? 'ooh' : 'dooh';
                        const res = await fetchData(`${window.location.origin}/aforo/delete-station-temp/${srvstring}`);
                        await alertGeneric(`${res?.status ? "Operación ejecutada correctamente" : 'Error al eliminar los aforos'}`, `${res?.status || 'Error al eliminar los aforos'}`, 2000, false, false, `${res?.status ? 'success' : 'error'}`);
            
                        // Recargar la página después de 2 segundos
                        setTimeout(() => {
                            window.location.href = `${window.location.origin}/aforo/aforo-${srvstring}/reserva/crear`; //http://127.0.0.1:8000/aforo/aforo-ooh/reserva/crear
                        }, 2000);
                    }
                });
    }

};

export default stepOnePreselectionFunctions;
