const editProfileBtn = () =>  {

    const form_modify_profile = document.getElementById('form_modify_profile');
    if (form_modify_profile) {
        const modifyProfileBtn = document.getElementById('modifyProfileBtn');
        const id_name = document.getElementById('id_name');
        const id_first_name = document.getElementById('id_first_name').value;
        const id_last_name = document.getElementById('id_last_name').value;
        const id_phone = document.getElementById('id_phone').value;
        const id_email = document.getElementById('id_email').value;
        const description_profile = document.querySelector('.description_profile');
        const form_modify_profile = document.querySelector('.form_modify_profile');

        const subtitle_phone = document.getElementById('subtitle_phone');
        const subtitle_firstName = document.getElementById('subtitle_firstName');
        const subtitle_lastName = document.getElementById('subtitle_lastName');

        subtitle_phone.innerHTML = id_phone;
        subtitle_firstName.innerHTML = id_first_name;
        subtitle_lastName.innerHTML = id_last_name;


        const input_first_name = document.getElementById('id_first_name');
        const input_last_name = document.getElementById('id_last_name');

        // Función para actualizar el valor del campo id_name
        const updateFullName = () => {
            id_name.value = input_first_name.value + " " + input_last_name.value;
        };

        // Llamar a la función updateFullName para establecer el valor inicial
        updateFullName();

        // Agregar escuchadores de eventos para los cambios en los campos de entrada
        input_first_name.addEventListener('input', updateFullName);
        input_last_name.addEventListener('input', updateFullName);



        const div_con_fondo_mobile = document.querySelector('.div_con_fondo_mobile');
        const id_first_name_mobile = div_con_fondo_mobile.querySelector('#id_first_name');
        const id_last_name_mobile = div_con_fondo_mobile.querySelector('#id_last_name');
        const id_name_mobile = div_con_fondo_mobile.querySelector('#id_name');

        //Cuando cambie el valor del input id_first_name se actualiza el valor del id_name
        id_first_name_mobile.addEventListener('input', function() {
            id_name_mobile.value = this.value+" "+id_last_name_mobile.value;
        });

        //Cuando cambie el valor del input id_last_name se actualiza el valor del id_name
        id_last_name_mobile.addEventListener('input', function() {
            id_name_mobile.value = id_first_name_mobile.value+" "+this.value;
        });


        modifyProfileBtn.addEventListener('click', () => {

            //seleccionar el label con for="id_first_name"
            const label_first_name = document.querySelector('label[for="id_first_name"]');
            //seleccionar el label con for="id_last_name"
            const label_last_name = document.querySelector('label[for="id_last_name"]');
            //seleccionar el label con for="id_phone"
            const label_phone = document.querySelector('label[for="id_phone"]');
            //seleccionar el label con for="id_email"
            const label_email = document.querySelector('label[for="id_email"]');

            //insertar un span con el texto * required dentro del label
            label_first_name.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');

            //insertar un span con el texto * required dentro del label
            label_last_name.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');

            //insertar un span con el texto * required dentro del label
            label_phone.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');

            //insertar un span con el texto * required dentro del label
            label_email.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');


            //añadir class d-none
            description_profile.classList.add('d-none');
            //quitar class d-none
            form_modify_profile.classList.remove('d-none');
            // ocultar modifyProfileBtn
            modifyProfileBtn.classList.add('d-none');

        });

        const openModifyProfileMobile = document.getElementById('openModifyProfileMobile');
        openModifyProfileMobile.addEventListener('click', (e) => {
            e.preventDefault();

            // Ocultar openModifyProfileMobile
            openModifyProfileMobile.classList.add('d-none');

            const modify_btn_mobile = document.querySelector('.modify_btn_mobile');
            // Mostrar modify_btn_mobile
            modify_btn_mobile.classList.remove('d-none');

            const info_container = document.querySelector('.info_container');
            // Ocultar info_container
            info_container.classList.add('d-none');

            const form_container_inputs = document.querySelector('.form_container_inputs');
            // Mostrar form_container_inputs
            form_container_inputs.classList.remove('d-none');

            //seleccionar el label con for="id_first_name"
            const label_first_name = form_container_inputs.querySelector('label[for="id_first_name"]');
            //seleccionar el label con for="id_last_name"
            const label_last_name = form_container_inputs.querySelector('label[for="id_last_name"]');
            //seleccionar el label con for="id_phone"
            const label_phone = form_container_inputs.querySelector('label[for="id_phone"]');
            //seleccionar el label con for="id_email"
            const label_email = form_container_inputs.querySelector('label[for="id_email"]');

            //insertar un span con el texto * required dentro del label
            label_first_name.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');

            //insertar un span con el texto * required dentro del label
            label_last_name.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');

            //insertar un span con el texto * required dentro del label
            label_phone.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');

            //insertar un span con el texto * required dentro del label
            label_email.insertAdjacentHTML('beforeend', '<span class="required_mark text-danger"> *</span>');


        });


    }



}

export default editProfileBtn;