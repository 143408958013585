import {alertConfirm, alertSuccess} from './helpers/swalAlerts.js';

const notificationFunctions = () => {
    
    const notifications_grid = document.getElementById('notifications_grid');
    if (!notifications_grid) return;


    const svg_container = document.querySelectorAll('.svg_container');
    const array_svg_container = Array.from(svg_container);

    if (array_svg_container.length > 0) {
        array_svg_container.forEach((svg_container) => {
            svg_container.addEventListener('click', () => {

                const url = svg_container.getAttribute('data-url');

                alertConfirm('¿Estás seguro de eliminar esta notificación?', "Esta acción no se puede revertir", 'Eliminar', 'Cancelar')
                    .then(async (result) => {
                        if (result.isConfirmed) {
                            // Redirección
                            window.location.href = url;
                            // Esperar un breve momento antes de mostrar el SweetAlert
                            await new Promise(resolve => setTimeout(resolve, 100));
                            alertSuccess('Notificación eliminada', '', 7000, false , false);
                        }
                    })
                
            });
        });
    }

}

export default notificationFunctions;