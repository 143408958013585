import {alertError, alertConfirm, alertSuccess} from './helpers/swalAlerts.js';

const bookingVerifyDetailsService = () => {
  const verify_list_table = document.querySelector('.verify_list_table');

  if (!verify_list_table) return;

  const verify_list_table_ooh = document.querySelector('.verify_list_table_ooh');
  const verify_list_table_dooh = document.querySelector('.verify_list_table_dooh');
  const form_evidences_installed = document.querySelector('#form_evidences_installed');
  const form_evidences_uninstalled = document.querySelector('#form_evidences_uninstalled');
  const form_feedback_report_installed = document.querySelector('#form_feedback_report_installed');

  let stringParam = "";
  let type = "";

  stringParam = verify_list_table_ooh ? 'ooh' : verify_list_table_dooh ? 'dooh' : '';

  // Obtén todos los elementos <input> de tipo "checkbox" dentro de la tabla
  const checkboxes = verify_list_table.querySelectorAll('input[type="checkbox"]');

  const approveService = async (checkboxName) => {
    //Separar el name _ 
    const name = checkboxName.split('_');
    const id = parseInt(name[1]);
    let url = '';


    if (type === 'installed') {
      url = `${window.location.origin}/aforo/put-installed/${stringParam}/${id}`;
    } else if (type === 'diurnal') {
      url = `${window.location.origin}/aforo/put-diurnal/${stringParam}/${id}`;
    } else if (type === 'uninstalled') {
      url = `${window.location.origin}/aforo/put-uninstalled/${stringParam}/${id}`;
    } else {
      url = `${window.location.origin}/aforo/put-approved/${stringParam}/${type}/${id}`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === true) {
        alertSuccess('¡Acción exitosa!', data.message, 2000, false, false)
          .then(() => {
            window.location.reload();
          })
      }

    } catch (error) {
      console.log(error);
    }
  };

  const swalAlertFunction = async (title, text, icon, question, checkboxName) => {

    Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true,
      confirmButtonColor: '#0933f1',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      showLoaderOnConfirm: true,
      confirmButtonText: question,
      allowOutsideClick: false,
      preConfirm: async () => {
        const checkbox = document.querySelector(`input[name="${checkboxName}"]`);
        checkbox.disabled = true;
        await approveService(checkboxName); 
        //simular el tiempo de espera de la petición
        //await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }).then((result) => {
      if (!result.isConfirmed) {
        const checkbox = document.querySelector(`input[name="${checkboxName}"]`);
        checkbox.checked = false;
      }
    });
  };

  // Agrega un evento "click" a cada checkbox
  checkboxes.forEach((checkbox) => {
    checkbox.addEventListener('click', async (event) => {
      const checkboxName = event.target.name;

      // Llama al servicio según el nombre del checkbox
      if (checkboxName.startsWith('art_')) {
        type = 'arts';
        swalAlertFunction('¿Estás seguro?', '¿Deseas aprobar estas artes?', 'warning', 'Sí, aprobar artes!', checkboxName);
      } else if (checkboxName.startsWith('unit_')) {
        type = 'unit';
        swalAlertFunction('¿Estás seguro?', '¿Deseas aprobar esta unidad de negocio?', 'warning', 'Sí, aprobar unidad!', checkboxName);
      } else if (checkboxName.startsWith('color_')) {
        type = 'color';
        swalAlertFunction('¿Estás seguro?', '¿Deseas aprobar estas muestras de color?', 'warning', 'Sí, aprobar color!', checkboxName);
      } else if (checkboxName.startsWith('installed_')) {
        type = 'installed';
        if (validateEvidences(checkboxName, type) === false) return;
        swalAlertFunction('¿Estás seguro?', '¿Deseas marcar como instalado este elemento?', 'warning', 'Si marcar como instalado!', checkboxName);
      } else if (checkboxName.startsWith('diurnal_')) {
        type = 'diurnal';
        swalAlertFunction('¿Estás seguro?', '¿Deseas marcar como diurno este elemento?', 'warning', 'Si marcar como diurno!', checkboxName);
      } else if (checkboxName.startsWith('uninstalled_')) {
        type = 'uninstalled';
        if (validateEvidences(checkboxName, type) === false) return;
        swalAlertFunction('¿Estás seguro?', '¿Deseas marcar como desinstalado este elemento?', 'warning', 'Si marcar como desinstalado!', checkboxName);
      }
    });
  });

  const validateEvidences = (checkboxName, type) => {
    let data_installed_evidences = "";
    let data_uninstalled_evidences = "";
  
    if (type === 'installed') {
        data_installed_evidences = document.querySelector(`input[name="${checkboxName}"]`).getAttribute('data-installed-evidences');
        if (data_installed_evidences === 'None' || data_installed_evidences === '' || data_installed_evidences === null) {
          alertError('¡Error!', 'Deben existir evidencias cargadas previamente para marcar como instalado este elemento.', 2000, false, false);
          // Desmarcar el checkbox
          const checkbox = document.querySelector(`input[name="${checkboxName}"]`);
          checkbox.checked = false;
          return false;
        } 
        return true;
    }
  
    if (type === 'uninstalled') {
        data_uninstalled_evidences = document.querySelector(`input[name="${checkboxName}"]`).getAttribute('data-uninstalled-evidences');
        if (data_uninstalled_evidences === 'None' || data_uninstalled_evidences === '' || data_uninstalled_evidences === null) {
          alertError('¡Error!', 'Deben existir evidencias cargadas previamente para marcar como desinstalado este elemento.', 2000, false, false);
          // Desmarcar el checkbox
          const checkbox = document.querySelector(`input[name="${checkboxName}"]`);
          checkbox.checked = false;
          return false;
        }
        return true;
    }
  
  }


  if (!form_evidences_installed && !form_evidences_uninstalled) return;
  let btnSubmit_form_evidences_installed = null;
  let btnSubmit_form_evidences_uninstalled = null;
  let input_form_evidences_installed = null;
  let input_form_evidences_uninstalled = null;

  if (form_evidences_installed) {
    btnSubmit_form_evidences_installed = form_evidences_installed.querySelector('#submit_installed');
    input_form_evidences_installed = form_evidences_installed.querySelector('#id_url_installed');

    // Validar que el campo no esté vacío
    btnSubmit_form_evidences_installed.addEventListener('click', (event) => {
      event.preventDefault();

      // Eliminar la clase de error
      input_form_evidences_installed.classList.remove('border-danger');

      if (input_form_evidences_installed.value === '') {
        // Agregar la clase de error
        input_form_evidences_installed.classList.add('border-danger');
        alertError('¡Error!', 'El campo no puede estar vacío', 2000, false, false);
      } else {
        // Mensaje de confirmación estas a punto de enviar las evidencias
        input_form_evidences_installed.classList.remove('border-danger');
        alertConfirm('¡Atención!', 'Estás a punto de enviar las evidencias, ¿Estás seguro?', 'Sí, enviar evidencias!', 'Cancelar')
          .then((result) => {
            if (result.isConfirmed) {
              alertSuccess('¡Acción exitosa!', 'Evidencias enviadas correctamente', 2000, false, false)
                .then(() => {
                  form_evidences_installed.submit(); 
                })
          }})
      }
    });
  }

  if (form_evidences_uninstalled) {
    btnSubmit_form_evidences_uninstalled = form_evidences_uninstalled.querySelector('#submit_uninstalled');
    input_form_evidences_uninstalled = form_evidences_uninstalled.querySelector('#id_url_uninstalled');

    // Validar que el campo no esté vacío
    btnSubmit_form_evidences_uninstalled.addEventListener('click', (event) => {
      event.preventDefault();

      // Eliminar la clase de error
      input_form_evidences_uninstalled.classList.remove('border-danger');

      if (input_form_evidences_uninstalled.value === '') {
        // Agregar la clase de error
        input_form_evidences_uninstalled.classList.add('border-danger');
        alertError('¡Error!', 'El campo no puede estar vacío', 2000, false, false);

      } else {
        // Mensaje de confirmación estas a punto de enviar las evidencias
        input_form_evidences_uninstalled.classList.remove('border-danger');
        alertConfirm('¡Atención!', 'Estás a punto de enviar las evidencias, ¿Estás seguro?', 'Sí, enviar evidencias!', 'Cancelar')
          .then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: '¡Acción exitosa!',
                text: 'Evidencias enviadas correctamente',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              }).then(() => {
                form_evidences_uninstalled.submit(); 
              })
          }})
      }
    });
  }

  if (form_feedback_report_installed) {
    const btnSubmit_form_feedback_report_installed = form_feedback_report_installed.querySelector('#submit_id_feedback_report');
    // console.log('btnSubmit_form_feedback_report_installed: ', btnSubmit_form_feedback_report_installed);
    const input_form_feedback_report_installed = form_feedback_report_installed.querySelector('#id_feedback_report');
    // console.log('input_form_feedback_report_installed: ', input_form_feedback_report_installed);

    // Validar que el campo no esté vacío
    btnSubmit_form_feedback_report_installed.addEventListener('click', (event) => {
      event.preventDefault();

      // Eliminar la clase de error
      input_form_feedback_report_installed.classList.remove('border-danger');

      if (input_form_feedback_report_installed.value === '') {
        // Agregar la clase de error
        input_form_feedback_report_installed.classList.add('border-danger');
        alertError('¡Error!', 'El campo no puede estar vacío', 2000, false, false);
      } else {
        // Mensaje de confirmación estas a punto de enviar el reporte
        input_form_feedback_report_installed.classList.remove('border-danger');
        alertConfirm('¡Atención!', 'Estás a punto de enviar el reporte, ¿Estás seguro?', 'Sí, enviar reporte!', 'Cancelar')
          .then((result) => {
            if (result.isConfirmed) {
              Swal.fire({
                title: '¡Acción exitosa!',
                text: 'Reporte enviado correctamente',
                icon: 'success',
                timer: 2000,
                showConfirmButton: false,
              }).then(() => {
                form_feedback_report_installed.submit(); 
              })
          }})
      }
    });
  }

  
  
};

export default bookingVerifyDetailsService;
