const addErrorClasses = (...inputs) => {
    let isValid = true;
    inputs.forEach((input) => {
        if (input.value === '') {
            input.classList.add('border-danger');
            isValid = false;
        }
    });
    return isValid;
};


const clearErrorClasses = (elements) => {
    elements.forEach((element) => element.classList.remove('border-danger'));
};


export { addErrorClasses, clearErrorClasses };