import Swal from 'sweetalert2';
import { alertHtml, alertConfirm } from './helpers/swalAlerts.js';

const validationCrudsForms = () => {
    const validateForm = (formId, idBtn, action) => {
        const form = document.getElementById(formId);
        if (!form) return;

        const MAXLENGTH = 50;

        const inputs = Array.from(form.querySelectorAll('[required]'));
        const save_button = form.querySelector(`#${idBtn}`);
        const error_container = form.querySelector('.error_container');
        const error_text = form.querySelector('.error_text');

        //! seleccionar los inputs Exepto type="hidden"
        const allInputs = Array.from(
            form.querySelectorAll('input, select')
        ).filter((input) => input.type !== 'hidden');

        //! los input de tipo email
        const emailInputs = Array.from(
            form.querySelectorAll('input[type="email"]')
        );

        //! los input de tipo date
        const idInitialDate = document.querySelector('#id_initial_date');
        const idFinishDate = document.querySelector('#id_finish_date');

        //! los input de tipo number, text, tel, email
        const MixedInputs = Array.from(
            form.querySelectorAll(
                'input[type="text"], input[type="tel"], input[type="email"]'
            )
        );
        // Añadirles el atributo maxlength="50"
        MixedInputs.forEach((input) =>
            input.setAttribute('maxlength', MAXLENGTH)
        );

        save_button.addEventListener('click', (e) => {
            e.preventDefault();

            let isErrorLong = false;
            let isErrorEmail = false;

            inputs.forEach((input) => {
                input.classList.remove('input_error');
            });

            // Validar longitud de los campos +50
            allInputs.forEach((input) => {
                if (input.value.length > MAXLENGTH) {
                    error_container.classList.remove('d-none');
                    error_text.innerHTML =
                        'Los campos no pueden tener más de 50 caracteres';
                    input.classList.add('input_error');
                    isErrorLong = true;
                } else {
                    input.classList.remove('input_error');
                }
            });
            

            // Validar formato de email con @
            emailInputs.forEach((input) => {
                if (!input.value.includes('@')) {
                    error_container.classList.remove('d-none');
                    error_text.innerHTML =
                        'El formato del email no es correcto';
                    input.classList.add('input_error');
                    isErrorEmail = true;
                } else {
                    input.classList.remove('input_error');
                }
            });

            // Validar fechas si ambos campos existen
            if (idInitialDate && idFinishDate) {
                const initialDateValue = idInitialDate.value;
                const finishDateValue = idFinishDate.value;

                if (initialDateValue && finishDateValue) {
                    const initialDate = new Date(initialDateValue);
                    const finishDate = new Date(finishDateValue);

                    if (initialDate > finishDate) {
                        error_container.classList.remove('d-none');
                        error_text.innerHTML =
                            'La fecha de inicio no puede ser posterior a la fecha de finalización';
                        idInitialDate.classList.add('input_error');
                        idFinishDate.classList.add('input_error');
                        return;
                    }
                }
            }

            if (isErrorLong) return;
            if (isErrorEmail) return;

            const emptyInputs = inputs.filter((input) => input.value === '');
            if (emptyInputs.length > 0) {
                error_container.classList.remove('d-none');
                error_text.innerHTML =
                    'Los campos marcados con * son obligatorios';
                emptyInputs.forEach((input) =>
                    input.classList.add('input_error')
                );
            } else {
                error_container.classList.add('d-none');

                if (action === 'Booking') {
                    const name_booking =
                        document.querySelector('#id_name').value;
                    // Seleccionar el texto
                    //const comercial_director_booking = document.querySelector('#id_commercial_director').selectedOptions[0].text;
                    const campain_booking =
                        document.querySelector('#id_campaign')
                            .selectedOptions[0].text;
                    const initial_date_booking =
                        document.querySelector('#id_initial_date').value;
                    const finish_date_booking =
                        document.querySelector('#id_finish_date').value;

                    //value del initial_date_booking
                    const initial_date_booking_value =
                        document.querySelector('#id_initial_date').value;
                    //value del finish_date_booking
                    const finish_date_booking_value =
                        document.querySelector('#id_finish_date').value;

                    //Value del min y max del initial_date_booking
                    const initial_date_booking_min =
                        document.querySelector('#id_initial_date').min;
                    const initial_date_booking_max =
                        document.querySelector('#id_initial_date').max;

                    //Value de min finish_date_booking
                    const finish_date_booking_min =
                        document.querySelector('#id_finish_date').min;

                    //Validar que el initial_date_booking_value sea mayor o igual que el initial_date_booking_min
                    if (initial_date_booking_value < initial_date_booking_min) {
                        error_container.classList.remove('d-none');
                        error_text.innerHTML = `La fecha de inicio no puede ser anterior a ${initial_date_booking_min}`;
                        idInitialDate.classList.add('input_error');
                        return;
                    }

                    //Validar que el initial_date_booking_value sea menor o igual que el id_finish_date
                    if (initial_date_booking_value > finish_date_booking) {
                        error_container.classList.remove('d-none');
                        error_text.innerHTML = `La fecha de inicio no puede ser posterior a ${finish_date_booking}`;
                        idInitialDate.classList.add('input_error');
                        return;
                    }

                    //Validar que el finish_date_booking_value sea mayor o igual que el finish_date_booking_min
                    if (finish_date_booking_value < finish_date_booking_min) {
                        error_container.classList.remove('d-none');
                        error_text.innerHTML = `La fecha de finalización no puede ser anterior a ${finish_date_booking_min}`;
                        idFinishDate.classList.add('input_error');
                        return;
                    }
                    alertHtml(
                        '¿Continuar?',
                        `
                    <b>DATOS RESERVA</b> <br> 
                    <b>Nombre:</b> ${name_booking} <br> 
                    <b>Campaña:</b> ${campain_booking} <br> 
                    <b>Fecha de inicio:</b> ${initial_date_booking} <br> 
                    <b>Fecha de finalización:</b> ${finish_date_booking} <br>`,
                        'Si, continuar',
                        'Cancelar'
                    ).then((result) => {
                        if (result.isConfirmed) {
                            // desactivar el boton de guardar y darle un estilo de cursor no permitido
                            save_button.disabled = true;
                            save_button.style.cursor = 'wait';
                            form.submit();
                        }
                    });
                } else {
                    alertConfirm(
                        `¿Estás seguro?`,
                        `Estás a punto de ${action.toLowerCase()} este registro.`
                    ).then((result) => {
                        if (result.isConfirmed) {
                            // desactivar el boton de guardar y darle un estilo de cargar
                            save_button.disabled = true;
                            save_button.style.cursor = 'wait';
                            form.submit();
                        }
                    });
                }
            }
        });
    };

    const deleteFunction = () => {
        const deleteForms = document.querySelectorAll('#deleteForm');
        const deleteButtons = document.querySelectorAll('.delete-button');

        if (deleteButtons.length === 0) return;

        deleteButtons.forEach((button, index) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();

                alertConfirm(
                    `¿Estás seguro?`,
                    `Estás a punto de eliminar este registro.`
                ).then((result) => {
                    if (result.isConfirmed) {
                        deleteForms[index].submit();
                    }
                });
            });
        });
    };

    const centralAgencyCreateFunction = () => {
        validateForm('central-agency-create', 'save-button', 'Guardar');
    };
    const centralAgencyEditFunction = () => {
        validateForm('central-agency-edit', 'save-button', 'Editar');
    };

    const contactAgencyCreateFunction = () => {
        validateForm('contact-agency-create', 'save-button', 'Guardar');
    };

    const contactAgencyEditFunction = () => {
        validateForm('contact-agency-edit', 'save-button', 'Editar');
    };

    const companyCreate = () => {
        validateForm('company-create', 'save-button', 'Guardar');
    };

    const companyEdit = () => {
        validateForm('company-edit', 'save-button', 'Editar');
    };

    const campaingCreate = () => {
        validateForm('campaign-create', 'save-button', 'Guardar');
    };

    const campaingEdit = () => {
        validateForm('campaign-edit', 'save-button', 'Editar');
    };

    const bookingCreate = () => {
        validateForm('booking-create', 'save-button', 'Booking');
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Asegura que tenga dos dígitos
        const day = String(date.getDate()).padStart(2, '0'); // Asegura que tenga dos dígitos
        return `${year}-${month}-${day}`;
    };

    const validationBookingDate = () => {
        let daysToAdd = 0;

        // Divs que contienen los campos de fecha
        const step_1_initial_date = document.querySelector(
            '.step_1_initial_date'
        );
        const step_1_finish_date = document.querySelector(
            '.step_1_finish_date'
        );

        // Si no están los dos Divs, no hacer nada
        if (!step_1_initial_date || !step_1_finish_date) return;

        let stringParam = '';
        const step_1_form_dooh = document.querySelector('.step_1_form_dooh');
        const step_1_form_ooh = document.querySelector('.step_1_form_ooh');
        // Campos de fecha
        const id_initial_date = document.querySelector('#id_initial_date');
        const id_finish_date = document.querySelector('#id_finish_date');

        // Desactivar el campo de fecha final y fecha inicial
        // Validar si el input tiene un type hidden
        if (id_initial_date.type !== 'hidden') {
            id_finish_date.disabled = true;
            id_initial_date.disabled = true;
            // Color al background del campo de fecha final
            id_finish_date.style.backgroundColor = '#efefef';
            id_initial_date.style.backgroundColor = '#efefef';
        }
        

        

        // Definir variable del array type_service_list
        let type_service_list = [];

        if (step_1_form_dooh) {
            stringParam = 'dooh';
        } else if (step_1_form_ooh) {
            stringParam = 'ooh';
        }

        const id_business_unit = document.getElementById('id_business_unit');
        const id_type_service = document.getElementById('id_type_service');

        if (id_type_service.type !== 'hidden') {
            //Deshabilitar id_type_service
            id_type_service.disabled = true;
            // Color al background del campo id_type_service
            id_type_service.style.backgroundColor = '#efefef';
        }

        //Evento change en id_business_unit
        id_business_unit.addEventListener('change', () => {
            // si stringParam es undefined, no se ejecuta el fetch
            if (!stringParam) return;

            // Obtener el valor del select id_business_unit
            const id_business_unit_value = id_business_unit.value;

            // Si value no es vacío habilitar id_type_service
            if (id_business_unit_value !== '') {
                //!  Hacer fetch a la API cambiar la url por la indicada
                const fetchServiceTypesByTypeService = async () => {
                    try {
                        const url = `${window.location.origin}/aforo/get_list_type_service_by_business_unit/${id_business_unit_value}/${stringParam}/`;
                        const response = await fetch(url);
                        const data = await response.json();

                        if (
                            data &&
                            data.type_service_list &&
                            data.type_service_list.length > 0
                        ) {
                            type_service_list = data.type_service_list;

                            //{pk: 3, name: 'Aforo OOH Vallas', amount_days: 5}
                            // Rellenar el select id_type_service con los datos de la API
                            id_type_service.innerHTML = '';
                            id_type_service.innerHTML += `<option value="">---------</option>`;
                            type_service_list.forEach((type_service) => {
                                id_type_service.innerHTML += `<option value="${type_service.pk}">${type_service.name}</option>`;
                            });

                            id_type_service.disabled = false;
                            id_type_service.style.backgroundColor = '#ffffff';
                        } else {
                            // Deshabilitar tipo de servicio
                            id_type_service.disabled = true;
                            id_type_service.style.backgroundColor = '#efefef';
                            // Seleccionar el value vacío
                            id_type_service.value = '';
                        }
                    } catch (error) {
                        console.error('Error:', error);
                    }
                };
                fetchServiceTypesByTypeService();
            } else {
                id_type_service.disabled = true;
                id_type_service.style.backgroundColor = '#efefef';
                // Seleccionar el value vacío
                id_type_service.value = '';
                // Vaciar y deshabilitar fecha inicial y final
                id_initial_date.value = '';
                id_initial_date.disabled = true;
                id_initial_date.style.backgroundColor = '#efefef';
                id_finish_date.value = '';
                id_finish_date.disabled = true;
                id_finish_date.style.backgroundColor = '#efefef';
            }
        });

        id_type_service.addEventListener('change', () => {
            // Obtener el valor del select id_type_service
            const id_type_service_value = id_type_service.value;
            // Si value no es vacío habilitar id_type_service
            if (id_type_service_value) {
                // Buscar en el objeto en type_service_list donde el pk sea igual a id_type_service_value
                const type_service = type_service_list.find(
                    (type_service) =>
                        type_service.pk === parseInt(id_type_service_value)
                );
                // Extraer el amount_days del objeto type_service
                const id_type_service_value_amount_days =
                    type_service.amount_days;

                daysToAdd = parseInt(id_type_service_value_amount_days);

                // Obtener la fecha actual
                const today = new Date();

                // Calcular la fecha de mañana
                const tomorrow = new Date(today);
                tomorrow.setDate(today.getDate() + 1);

                // Calcular la fecha de "mañana + daysToAdd"
                const maxDate = new Date(tomorrow);
                maxDate.setDate(tomorrow.getDate() + daysToAdd);

                // Formatear la fecha de mañana como una cadena "YYYY-MM-DD"
                const tomorrowFormatted = formatDate(tomorrow);

                // Calcular la fecha de "mañana + 1"
                const tomorrowPlusOne = new Date(tomorrow);
                tomorrowPlusOne.setDate(tomorrow.getDate() + 1);

                // Formatear la fecha de mañana + 1 como una cadena "YYYY-MM-DD"
                const tomorrowPlusOneFormatted = formatDate(tomorrowPlusOne);

                // Formatear la fecha máxima como una cadena "YYYY-MM-DD"
                const maxDateFormatted = maxDate.toISOString().split('T')[0];

                id_initial_date.min = tomorrowFormatted;
                id_initial_date.value = tomorrowFormatted;

                id_finish_date.min = tomorrowPlusOneFormatted;

                // Obtener el data-actual-profile de id="save-button"
                const save_button = document.querySelector('#save-button');
                const dataActualProfile = save_button.getAttribute(
                    'data-actual-profile'
                );

                // Si el data-actual-profile es "director comercial" se quita la mimitacion de id_initial_date.max
                if (dataActualProfile === 'director') {
                    //quitar el maximo
                    id_initial_date.removeAttribute('max');
                } else {
                    id_initial_date.max = maxDateFormatted;
                }
                // Habilitar el campo de fecha final
                id_initial_date.disabled = false;
                id_initial_date.style.backgroundColor = '#ffffff';

                // Habilitar el campo de fecha final
                id_finish_date.disabled = false;

                // Color al background del campo de fecha final
                id_finish_date.style.backgroundColor = '#ffffff';
            } else {
                id_initial_date.value = '';
                id_initial_date.disabled = true;
                id_initial_date.style.backgroundColor = '#efefef';

                id_finish_date.value = '';
                id_finish_date.disabled = true;
                id_finish_date.style.backgroundColor = '#efefef';
            }

            return;
        });

        id_initial_date.addEventListener('change', function () {
            // Obtener la fecha de inicio seleccionada por el usuario
            const selectedStartDate = new Date(id_initial_date.value);

            // Habilitar el campo de fecha final
            id_finish_date.disabled = false;

            // Color al background del campo de fecha final
            id_finish_date.style.backgroundColor = '#ffffff';

            // Establecer el mínimo de la fecha final como la fecha de inicio seleccionada por el usuario
            const minDate = new Date(selectedStartDate);
            minDate.setDate(selectedStartDate.getDate() + 1); // Asegurarse de que la fecha mínima sea al menos un día después de la fecha de inicio

            // Formatear la fecha mínima como una cadena "YYYY-MM-DD"
            const minDateFormatted = minDate.toISOString().split('T')[0];
            id_finish_date.min = minDateFormatted;

            // Remover el evento
            return;
        });
    };

    return {
        centralAgencyCreateFunction,
        centralAgencyEditFunction,
        contactAgencyCreateFunction,
        contactAgencyEditFunction,
        companyCreate,
        companyEdit,
        campaingCreate,
        campaingEdit,
        deleteFunction,
        bookingCreate,
        validationBookingDate,
    };
};

export default validationCrudsForms;
