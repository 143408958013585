import {getTotalAforosAdded, showTotalAforosAdded} from './aforosFunctions.js';

const updateTotalAforos = async () => {
    const selected_container = document.querySelector('.selected_container');
    let total_aforos_added = [];

    if (selected_container) {
        total_aforos_added = await getTotalAforosAdded(
            `${window.location.origin}/aforo/get-station-temp`
        );

        // console.log('total_aforos_added: ', total_aforos_added);
    }

}


const aforosPreselectedBoxFunctions = async () => {
    const aforos_container = document.getElementById('aforos_container');
    // Si estoy en la página de aforos return
    if (aforos_container) return;


    const create_booking = document.getElementById('create_booking');
    if (!create_booking) return;
    const selected_container = document.querySelector('.selected_container');
    if (!selected_container) return;

    const total_aforos_added = await updateTotalAforos();
    if (!total_aforos_added) return;

    // console.log('selected_container: ', selected_container);
    selected_container.classList.remove('d-none');
    const type = total_aforos_added[0]?.type;

    selected_container.addEventListener('click', (e) => {
    let urlpath = ""
    type === 'OOH' ? urlpath = "/aforo/aforo-ooh/reserva/crear" : urlpath = "/aforo/aforo-dooh/reserva/crear"

        alertConfirm(
            '¿Ya finalizaste la selección de aforos?',
            'Si aceptas, pasaras a la ventana de creación de reservas.',
            'Continuar',
            'Cancelar'
        ).then(async (result) => {
            // redireccionar a la url
            if (result.isConfirmed) {
                // Redireccionar a la url
                window.location.href = urlpath;
            }
        });
    });

}

export default aforosPreselectedBoxFunctions;