import { alertError } from './swalAlerts.js';

const validateDate = (since_date, until_date) => {
    // Validar que la fecha inicial no sea mayor a la fecha final
    if (since_date.value > until_date.value) {
        alertError('¡Error!', 'La fecha inicial no puede ser mayor a la fecha final', 2000, false);
        // Añadir clases de error
        since_date.classList.add('border-danger');
        until_date.classList.add('border-danger');
        return false;
    } else {
        return true;
    }
}

export { validateDate }