const formatCurrencyCop = () => {

    // console.log('formatCurrencyCop');

    //seleccionar todos los elementos con la clase currency   
    const currencyElements = document.querySelectorAll('.currency');
    //console.log(currencyElements);

    if (!currencyElements) {
        return;
    }

    //recorrer todos los elementos seleccionados quitarle todo lo que tenga despues de una columnGap: 
    currencyElements.forEach(element => {
        let content = element.textContent.split(',')[0];
        // añadirle un punto cada 3 caracteres y añadirle antes el signo de pesos
        content = content.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        content = '$ ' + content;
        element.textContent = content;
    });

}

export default formatCurrencyCop;