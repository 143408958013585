import { fetchData } from './helpers/fetchData.js';
import {
    alertConfirm,
    alertError,
    alertSuccess,
    alertHtml,
    simpleAlert,
    alertGeneric
} from './helpers/swalAlerts.js';
import { showTotalAforosAdded } from './helpers/innerHtmlSpan.js';



const bookingPreselectionValidation = async () => {

    const aforos_container = document.getElementById('aforos_container');
    // Si estoy en la página de aforos return
    if (aforos_container) return;


    let total_aforos_added = [];
    const getTotalAforosAdded = async (url) => {
        try {
            const response = await fetchData(url);
    
            // Si es 200, la solicitud fue exitosa
            if (response) {
                return response?.booking;
            } else {
                // Mostrar una alerta de error con el mensaje recibido del servidor
                alertError(response.message || 'Error al obtener los aforos.');
            }
        } catch (error) {
            console.error('Error al obtener los aforos:', error);
        }
    };
    
    // seleccionar el body con la clase login
    const loginBody = document.querySelector('body.login');
    if (loginBody) return;

    total_aforos_added = await getTotalAforosAdded(
        `${window.location.origin}/aforo/get-station-temp`
    );

    // console.log('Se ejecuto el getTotalAforosAdded xxx' , total_aforos_added);
    // si existe total_aforos_added y total_aforos_added?.count es mayor a 0
    if (total_aforos_added && total_aforos_added[0]?.count > 0) {
        const selected_container = document.querySelector('.selected_container');
        if (!selected_container) return;
        // console.log('selected_container: ', selected_container);
        showTotalAforosAdded('selected_text', total_aforos_added[0]?.count);
        selected_container.classList.remove('d-none');
    }


    

    const booking_list_container = document.querySelector('.booking_list_container');
    if (!booking_list_container) return;
    // console.log('Estamos dentro de booking_list_container');

    const create_booking = document.getElementById('create_booking');
    create_booking.addEventListener('click', async () => {
        const url = create_booking.getAttribute('data-url');
        const ubication = create_booking.getAttribute('data-location');
        
        
        if ((ubication === 'dooh' && total_aforos_added[0]?.type === 'OOH') || (ubication === 'ooh' && total_aforos_added[0]?.type === 'DOOH')) {

            const result = await alertHtml(
                'Tienes aforos pre-seleccionados',
                `Ya tienes pre-seleccionados <strong>${total_aforos_added[0]?.count}</strong> aforos en la categoría <strong>${total_aforos_added[0]?.type}</strong> , unidad de negocio:  <strong>${total_aforos_added[0]?.business_unit}</strong> ; para seleccionar aforos de esta categoría, debes eliminar los aforos pre-seleccionados.`,
                'Eliminar pre-selección',
                'Cerrar',
                'info',
                '#d33',
                '#0933f1'
            );

                // Imprimir en consola si le da click en eliminar aforos
            if (result.isConfirmed) {
                // Preguntar si desea eliminar los aforos
                const resultConfirm = await alertConfirm(
                    '¿Estás seguro?',
                    'Si aceptas, se eliminarán todos los aforos pre-seleccionados.',
                    'Eliminar pre-selección',
                    'Cancelar'
                );
        
                // Si está confirmado
                if (resultConfirm.isConfirmed) {
                    let srvstring = ubication === 'ooh' ? 'dooh' : 'ooh';
                    const res = await fetchData(`${window.location.origin}/aforo/delete-station-temp/${srvstring}`);
                    await alertGeneric(`${res?.status ? "Operación ejecutada correctamente" : 'Error al eliminar los aforos'}`, `${res?.status || 'Error al eliminar los aforos'}`, 2000, false, false, `${res?.status ? 'success' : 'error'}`);
        
                    // Redireccionar a la url después de 2 segundos
                    setTimeout(() => {
                        window.location.href = url;
                    }, 2000);
                }
            }

        } else {
            window.location.href = url;
        }

    });

}

export default bookingPreselectionValidation;