const cardFunctions = () => {
    const moda_booking = document.querySelector('.moda_booking');

    if (moda_booking) {
        const item_desc = document.querySelectorAll('.item_desc');

        item_desc.forEach((element) => {
            const text = element.textContent.trim();
            
            // Verificar si el texto es de una sola línea (no contiene saltos de línea)
            if (!text.includes('\n') && text.length > 14) {
                // Agregar un espacio después del carácter 14
                const modifiedText = text.slice(0, 14) + '- ' + text.slice(14);
                element.textContent = modifiedText;
            }
        });
    }

    const filter_ooh = document.getElementById('filter_ooh');
    const filter_dooh = document.getElementById('filter_dooh');

    // Si estoy en filter_ooh o filter_dooh

    if (filter_ooh || filter_dooh) {
        const item_desc = document.querySelectorAll('.item_desc');

        item_desc.forEach((element) => {
            const text = element.textContent.trim();
            
            // Verificar si el texto es de una sola línea (no contiene saltos de línea)
            if (!text.includes('\n') && text.length > 14) {
                // Agregar un espacio después del carácter 14
                const modifiedText = text.slice(0, 14) + '- ' + text.slice(14);
                element.textContent = modifiedText;
            }
        });
    }
}

export default cardFunctions;

