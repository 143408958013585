import { fetchData } from "./helpers/fetchData";
import { convertToSelectOptions, populateSelect } from "./helpers/populateSelect";

const clienteAnuncianteFunctions = async () => {
    const company_create_form = document.getElementById('company-create');
    const advertisingcompany_form = document.getElementById('advertisingcompany_form');
    if (!advertisingcompany_form && !company_create_form) return;

    const select_id_sector = document.getElementById('id_sector');
    const select_id_sub_sector = document.getElementById('id_sub_sector');

    // Poblar select_id_sub_sector
    populateSelect(select_id_sub_sector);

    const loadSubsectors = async (sectorId) => {
        select_id_sub_sector.disabled = true;
        select_id_sub_sector.style.backgroundColor = 'lightgray';
        const subsectors = await fetchData(`${window.location.origin}/cliente/get-subsector/${sectorId}`);
        const { sub_sector } = subsectors;
        if (!sub_sector) return;
        const options = convertToSelectOptions(sub_sector, 'pk', 'name');
        populateSelect(select_id_sub_sector, options);
        select_id_sub_sector.disabled = false;
        select_id_sub_sector.style.backgroundColor = 'white';
    };

    // Cargar subsectores cuando cambie el sector seleccionado
    select_id_sector.addEventListener('change', async () => {
        if (!select_id_sector.value) {
            populateSelect(select_id_sub_sector);
            select_id_sub_sector.disabled = true;
            select_id_sub_sector.style.backgroundColor = 'lightgray';
            select_id_sub_sector.value = '';
            return;
        }

        await loadSubsectors(select_id_sector.value);
    });

    // Cargar subsectores si el sector ya tiene un valor
    const id_sector = select_id_sector.value;
    if (id_sector) {
        await loadSubsectors(id_sector);
    }
};

export default clienteAnuncianteFunctions;