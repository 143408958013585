const selectAdmin = () => {
    // Variable de control para asegurarse de que esta verificación se ejecute solo una vez
    let isInitialLoad = true;
    let stringParam;

    const filter_ooh = document.getElementById("filter_ooh");
    const filter_dooh = document.querySelector('.filter_dooh');

    if (!filter_ooh && !filter_dooh) return;

    if (filter_ooh) {
        stringParam = "ooh";
    }
    
    if (filter_dooh) {
        stringParam = "dooh";
    }

    const id_business_unit = document.getElementById("id_business_unit");
    const select_id_station = document.getElementById("id_station"); // Cambio de nombre aquí
    const select_id_location = document.getElementById("id_location");
    const select_id_element_type = document.getElementById("id_element_type");


    if (!id_business_unit || !select_id_station || !select_id_location || !select_id_element_type /* || !id_state */) return;

    const addDefaultOption = (selectElement, text = "---------", selected = true, clear = false) => {
        if (clear) {
            selectElement.innerHTML = "";
        }
        const defaultOption = document.createElement("option");
        defaultOption.value = "";
        defaultOption.selected = selected; // Agregar el atributo selected si es necesario
        defaultOption.textContent = text;
        selectElement.appendChild(defaultOption);
    };

    

    const fetchData = async (url, selectElement) => {
        try {
            const response = await fetch(url);
            const data = await response.json();
            selectElement.innerHTML = "";
            addDefaultOption(selectElement);
            data.locations.forEach((item) => {
                const option = document.createElement("option");
                option.value = item.pk;
                option.innerText = item.name;
                selectElement.appendChild(option);
            });
        } catch (error) {
            console.error("Error al obtener y procesar los datos:", error);
            addDefaultOption(selectElement, "---------", true, true);
            addDefaultOption(select_id_location, "---------", true, true);
         //   addDefaultOption(select_id_element_type, "---------", true, true); //? Delete depending select
        }
    };

    id_business_unit.addEventListener("change", () => {

        // si stringParam es undefined, no se ejecuta el fetch
        if (!stringParam) return;


        const id_bu = id_business_unit.value;
        addDefaultOption(select_id_location, "---------", true, true);
       // addDefaultOption(select_id_element_type, "---------", true, true); //? Delete depending select

        if (!id_bu) {
            addDefaultOption(select_id_station, "---------", true, true); // Cambio de nombre aquí
            addDefaultOption(select_id_location, "---------", true, true);
           // select_id_element_type.innerHTML = ""; //? Delete depending select
            return;
        }

        fetchData(`${window.location.origin}/aforo/get_station_list_by_business_unit/${id_bu}/${stringParam}`, select_id_station); // Cambio de nombre aquí
    });

    select_id_station.addEventListener("change", () => {
        const id_station = select_id_station.value;
        if (!id_station) {
            addDefaultOption(select_id_location, "---------", true, true);
          //  addDefaultOption(select_id_element_type, "---------", true, true); //? Delete depending select
            return;
        }
        fetchData(`${window.location.origin}/aforo/get_location_list_by_station/${id_station}/`, select_id_location);

        // Obtener el id_station seleccionado y usarlo para obtener el elemento_type
        fetchData(`${window.location.origin}/aforo/get-type-element-by-station/${id_station}/`, select_id_element_type);
    });

    if (
        isInitialLoad &&
        (id_business_unit.value !== "" ||
        select_id_station.value !== "" || 
        select_id_location.value !== "" ||
        select_id_element_type.value !== "")
    ) {
        // Si ya tienen valores seleccionados, no hacemos nada y marcamos la carga inicial como completa
        isInitialLoad = false;
    } 

    // Agrega la opción predeterminada "No disponible" a los selects al cargar la página
    if (isInitialLoad) {
        addDefaultOption(select_id_station, "---------", true, true); // Cambio de nombre aquí
        addDefaultOption(select_id_location, "---------", true, true);
      //  addDefaultOption(select_id_element_type, "---------", true, true); //? Delete depending select
    }

    return fetchData;
}

export default selectAdmin;