const validationsFilters = () => {
    // Validación filtros fechas reservas
    const filter_ooh = document.querySelector('#filter_ooh');
    const filter_booking = document.querySelector('.filter_booking');

    if (filter_ooh || filter_booking) {

        const id_initial_date = document.querySelector('#id_initial_date');
        const id_finish_date = document.querySelector('#id_finish_date');

        if (!id_initial_date || !id_finish_date) return;

        // Obtener referencias a los campos de fecha
        let initialDateField = document.getElementById("id_initial_date");
        let finishDateField = document.getElementById("id_finish_date");

        // Agregar un evento 'change' al campo de fecha de inicio
        initialDateField.addEventListener("change", function () {
            // Obtener las fechas seleccionadas
            let initialDate = new Date(initialDateField.value);

            // Establecer el atributo 'min' en el campo de finalización
            finishDateField.setAttribute("min", initialDateField.value);

            let finishDate = new Date(finishDateField.value);

            // Comprobar si la fecha de inicio es posterior a la fecha de finalización
            if (initialDate > finishDate) {
                // Mostrar un mensaje de error
                alert("La fecha de inicio no puede ser posterior a la fecha de finalización");
                // Restablecer el valor del campo de fecha de inicio
                initialDateField.value = "";
            }
        });

        // Agregar un evento 'change' al campo de fecha de finalización
        finishDateField.addEventListener("change", function () {
            // Obtener las fechas seleccionadas
            let finishDate = new Date(finishDateField.value);

            // Establecer el atributo 'max' en el campo de inicio
            initialDateField.setAttribute("max", finishDateField.value);

            let initialDate = new Date(initialDateField.value);

            // Comprobar si la fecha de finalización es anterior a la fecha de inicio
            if (finishDate < initialDate) {
                // Mostrar un mensaje de error
                alert("La fecha de finalización no puede ser anterior a la fecha de inicio");
                // Restablecer el valor del campo de fecha de finalización
                finishDateField.value = "";
            }
        });
    }
};

export default validationsFilters;