const isLoading = (isLoading) => {

    const spinner = document.getElementById('spinner_container');

    if (!spinner) return;

    if (isLoading) {
        spinner.classList.remove('d-none');
    } else {
        spinner.classList.add('d-none');
    }

}

export default isLoading;