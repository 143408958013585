const progressBar = () => {
    const progress_bar_container = document.querySelector('.progress_bar_container');
    if (!progress_bar_container) return;

    const progressBar = document.querySelector('.progressbar');
    if (!progressBar) return;

    const form_box_step_1 = document.querySelector('.form_box_step_1');

    if (form_box_step_1) {

        const inputs = form_box_step_1.querySelectorAll('select, input[type="date"], input[type="text"]');
        const progress_numer_text = document.querySelector('.progress_numer_text');

        // Calcular el incremento de ancho por cada input
        const incrementWidth = 10; // Aumento del 10% por cada input

        // Inicializar el ancho de la barra
        let currentWidth = 0;

        const inputChanged = new Set(); // Conjunto para llevar un seguimiento de los inputs modificados

        inputs.forEach(input => {
            input.addEventListener('input', () => {
                if (input.value.trim() !== '' && !inputChanged.has(input)) {
                    currentWidth += incrementWidth;
                    progressBar.style.setProperty('--progress-width', `${currentWidth}%`);
                    inputChanged.add(input);
                } else if (input.value.trim() === '' && inputChanged.has(input)) {
                    // Revertir el aumento si el input vuelve a estar vacío
                    currentWidth -= incrementWidth;
                    progressBar.style.setProperty('--progress-width', `${currentWidth}%`);
                    inputChanged.delete(input);
                }

                // Actualizar el texto de porcentaje
                progress_numer_text.textContent = `${Math.min(currentWidth, 100)}%`;
            });
        });
    }

    const form_box_step_2 = document.querySelector('.form_box_step_2');
    if (form_box_step_2) {
        
        const accordion_item_spaces = document.querySelectorAll('.accordion_item_spaces');
        const progress_numer_text = document.querySelector('.progress_numer_text');

        if (accordion_item_spaces.length === 0) {
            // Si no hay elementos, establecer el ancho en 70%
            progressBar.style.setProperty('--progress-width', `${70}%`);
            progress_numer_text.textContent = `${70}%`;
        } else {    
            // Si hay elementos, establecer el ancho en 90%
            progressBar.style.setProperty('--progress-width', `${90}%`);
            progress_numer_text.textContent = `${90}%`;
        }   

        const status_booking_text = document.querySelector('.status_booking_text');
        if (status_booking_text) {
            if (status_booking_text.textContent === 'Cerrado') {
                // Si el estado es cancelada, establecer el ancho en 100%
                progressBar.style.setProperty('--progress-width', `${100}%`);
                progress_numer_text.textContent = `${100}%`;
            }
        }
    }
}

export default progressBar;
