const filterBookingVerify = () => {

    const filter_ooh_verify = document.querySelector('.filter_ooh_verify'); 
    const filter_dooh_verify = document.querySelector('.filter_dooh_verify');

    const remove_filters = document.querySelector('#remove_filters');
    const clean_filters = document.querySelector('#clean_filters');
    // Si no estoy en OOH ni en DOOH, no ejecutar el código
    if (!filter_ooh_verify && !filter_dooh_verify) return;


    const filters_container = document.querySelector('.filters_container');
    const remove_filters_button = document.querySelector('#clean_filters');

    // !variables de inputs
    const name = document.querySelector('#id_name');
    const commercial_director = document.querySelector('#id_commercial_director');
    const campaign = document.querySelector('#id_campaign');
    const initial_date = document.querySelector('#id_initial_date');
    const finish_date = document.querySelector('#id_finish_date');
    const state_purchase_order = document.querySelector('#id_state');


    // !Obtener los valores de los query params
    const urlParams = new URLSearchParams(window.location.search);

    const name_url_param = urlParams.get('name');
    const commercial_director_url_param = urlParams.get('commercial_director');
    const campaign_url_param = urlParams.get('campaign');
    const initial_date_url_param = urlParams.get('initial_date');
    const finish_date_url_param = urlParams.get('finish_date');
    const state_purchase_order_url_param = urlParams.get('state');

    

    function toggleRemoveFiltersButton() {
        const filters = filters_container.querySelectorAll('.filter');
        const isFiltersActive = filters.length > 0;
        
        if (isFiltersActive) {
            remove_filters_button.classList.remove('d-none');
        } else {
            remove_filters_button.classList.add('d-none');
        }
    }


    function addFilter(filterClass, value) {
        const previousFilter = filters_container.querySelector(`.filter.${filterClass}`);
        if (previousFilter) {
            previousFilter.remove();
        }

        if (value && value.trim() !== '') {
            const filter = document.createElement('div');
            filter.classList.add('filter');
            filter.textContent = value;
            filter.classList.add(filterClass); // Agregar clase específica
            filters_container.appendChild(filter);
        }

        // Llamar a la función para mostrar u ocultar el botón "remove_filters"
        toggleRemoveFiltersButton();
    }


    // Funcionalidad para remover filtros
    remove_filters.addEventListener('click', (e) => {
        e.preventDefault();
        // const url = remove_filters.getAttribute('data-url');
        // window.location.href = url;

        const modal = document.querySelector('.modal_filter');

        if (modal) {
            // obtener la url completa sin ningún query param
            const urlWithoutParams = window.location.href.split('?')[0];
            // redireccionar a la url
            window.location.href = urlWithoutParams;
        } else {
            const url = remove_filters.getAttribute('data-url');
            window.location.href = url;
        }
    });

    // Funcionalidad para limpiar filtros
    clean_filters.addEventListener('click', (e) => {
        e.preventDefault();
        //dar click al botón de remover filtros
        remove_filters.click();
    });


    const filtersBoxFunction = () => {
    
        //! Al cambiar name se añade el filtro
        name.addEventListener('input', () => {
            // Validar si filterName ya existe
            const previousFilter = filters_container.querySelector(`.filter.filter_name`);
            if (previousFilter) {
                previousFilter.remove();
            }
    
            // Crear el filtro de nombre
            const filterName = document.createElement('div');
            filterName.classList.add('filter', 'filter_name', 'd-none');
            filters_container.appendChild(filterName);
    
            const inputValue = name.value;
            filterName.textContent = "Nombre";
    
            if (inputValue.trim() === '') {
                filterName.classList.add('d-none');
            } else {
                filterName.classList.remove('d-none');
            }
        });
        
    
        //! Al cambiar commercial_director se añade el filtro
        commercial_director.addEventListener('change', () => {
            addFilter('commercial_director', "Director Comercial");
            // Activar el select de station
            // station.disabled = false;
        });
    
        //! Al cambiar campaign se añade el filtro
        campaign.addEventListener('change', () => {
            addFilter('campaign', "Campaña");
            // Activar el select de location
            // location.disabled = false;
            // // Activar el select de element_type
            // element_type.disabled = false;
        });
    
        //! Al cambiar initial_date se añade el filtro
        initial_date.addEventListener('change', () => {
            addFilter('initial_date', "Fecha Inicial");
        });
    
        //! Al cambiar finish_date se añade el filtro
        finish_date.addEventListener('change', () => {
            addFilter('finish_date', "Fecha Final");
        });

        //! Al cambiar state_purchase_order se añade el filtro
        state_purchase_order.addEventListener('change', () => {
            //const value = state.options[state.selectedIndex].text;
            addFilter('state_purchase_order', "Estado de reserva");
        });
    
    }


    const queryParamsFilterBox = () => {
        // Mostrar los filtros si hay parámetros de consulta en la URL
        if (name_url_param) {
            const filterName = document.createElement('div');
            filterName.classList.add('filter', 'filter_name');
            filterName.textContent = "Nombre";
            filters_container.appendChild(filterName);
        }
    
        if (commercial_director_url_param) {
            const filterCommercialDirector = document.createElement('div');
            filterCommercialDirector.classList.add('filter', 'filter_commercial_director');
            filterCommercialDirector.textContent = "Director Comercial";
            filters_container.appendChild(filterCommercialDirector);
        } 
         
    
        if (campaign_url_param) {
            const filterCampaign = document.createElement('div');
            filterCampaign.classList.add('filter', 'filter_campaign');
            filterCampaign.textContent = "Campaña";
            filters_container.appendChild(filterCampaign);
        } 

        if (initial_date_url_param) {
            const filterInitialDate = document.createElement('div');
            filterInitialDate.classList.add('filter', 'filter_initial_date');
            filterInitialDate.textContent = "Fecha Inicial";
            filters_container.appendChild(filterInitialDate);
        } 
    
        if (finish_date_url_param) {
            const filterFinishDate = document.createElement('div');
            filterFinishDate.classList.add('filter', 'filter_finish_date');
            filterFinishDate.textContent = "Fecha Final";
            filters_container.appendChild(filterFinishDate);
        } 

        if (state_purchase_order_url_param) {
            const filterState = document.createElement('div');
            filterState.classList.add('filter', 'filter_state_purchase_order');
            filterState.textContent = "Estado de reserva";
            filters_container.appendChild(filterState);
        }
    }


    filtersBoxFunction();
    queryParamsFilterBox();
    toggleRemoveFiltersButton();

};

export default filterBookingVerify;
