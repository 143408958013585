import {
    alertConfirm,
    alertError,
    alertSuccess,
    alertHtml,
    simpleAlert,
    alertGeneric
} from './helpers/swalAlerts.js';

import { performPostRequest, fetchData } from './helpers/fetchData.js';
import { showTotalAforosAdded } from './helpers/innerHtmlSpan.js';

const registerAforo = async (url, data) => {

    try {
        const response = await performPostRequest(url, data);
        // console.log('response: ', response);
        const res = await response.json();
        // console.log('res: ', res);

        // Si es 200, la solicitud fue exitosa
        if (response.status === 200) {
            // Tomar la ulr completa sin query params
            const urlWithoutQueryParams = window.location.href.split('?')[0];

            // Mostrar una alerta de éxito
            // console.log('Redireccionando a:', urlWithoutQueryParams);
            await alertSuccess('Aforo registrado correctamente.');

            // Redireccionar a la url sin query params
            window.location.href = urlWithoutQueryParams;

        } else {
            // Mostrar una alerta de error con el mensaje recibido del servidor
            alertError(res.error || 'Error al registrar el aforo.');
        }

        setTimeout(() => {}, 2000);
    } catch (error) {
        console.error('Error al registrar el aforo:', error);
    }
};

export const getTotalAforosAdded = async (url) => {
    try {
        const response = await fetchData(url);

        // Si es 200, la solicitud fue exitosa
        if (response) {
            return response?.booking;
        } else {
            // Mostrar una alerta de error con el mensaje recibido del servidor
            alertError(response.message || 'Error al obtener los aforos.');
        }
    } catch (error) {
        console.error('Error al obtener los aforos:', error);
    }
};

const updateTotalAforos = async (ubication) => {
    // console.log('ubication: ', ubication);
    const selected_container = document.querySelector('.selected_container');
    let total_aforos_added = [];

    // Obtener el total de aforos  return
    if (selected_container) {
        total_aforos_added = await getTotalAforosAdded(
            `${window.location.origin}/aforo/get-station-temp`
        );
        const urlParams = new URLSearchParams(window.location.search);
        const pathTotalAforosAdded = total_aforos_added[0]?.path;
        
        if (pathTotalAforosAdded) {

            if((total_aforos_added[0]?.type === 'OOH' && ubication == "ooh") || (total_aforos_added[0]?.type === 'DOOH' && ubication == "dooh")){

                // Extraer la cadena de parámetros de la propiedad "path"
                const paramsToAdd = new URLSearchParams(pathTotalAforosAdded.split('?')[1]);
            
                // Obtener los valores de los parámetros de pathTotalAforosAdded
                const businessUnitToAdd = paramsToAdd.get('business_unit');
                const dateInitToAdd = paramsToAdd.get('date_init');
                const dateEndToAdd = paramsToAdd.get('date_end');
            
                // Verificar si los parámetros están en la URL y son iguales
                if (
                    (businessUnitToAdd && businessUnitToAdd === urlParams.get('business_unit')) &&
                    (dateInitToAdd && dateInitToAdd === urlParams.get('date_init')) &&
                    (dateEndToAdd && dateEndToAdd === urlParams.get('date_end'))
                ) {
                    // Los parámetros ya están en la URL y son iguales, no es necesario redireccionar
                    // console.log('Los parámetros ya están en la URL y son iguales.');
                    const id_business_unit = document.getElementById('id_business_unit');
                    const date_init = document.getElementById('date_init');
                    const date_end = document.getElementById('date_end');

                    // Desactivar los campos de fecha y unidad de negocio y colorearlos de gris
                    id_business_unit.disabled = true;
                    id_business_unit.style.background = '#e9ecef';
                    date_init.disabled = true;
                    date_init.style.background = '#e9ecef';
                    date_end.disabled = true;
                    date_end.style.background = '#e9ecef';

                    // setear los valores de los campos de fecha 
                    date_init.value = dateInitToAdd;
                    date_end.value = dateEndToAdd;

                } else {
                    // Al menos uno de los parámetros no está en la URL o es diferente
                    // Actualizar o agregar manualmente los parámetros en la URL actual
                    if (businessUnitToAdd) {
                        urlParams.set('business_unit', businessUnitToAdd);
                    }
                    if (dateInitToAdd) {
                        urlParams.set('date_init', dateInitToAdd);
                    }
                    if (dateEndToAdd) {
                        urlParams.set('date_end', dateEndToAdd);
                    }

                    // Redirigir a la nueva URL con los parámetros actualizados
                    const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
                    // console.log('Redirigiendo a:', newUrl);
                    window.location.href = newUrl;
                }
            }
        }



        const add_spaces_btn = document.querySelectorAll('#add_spaces_btn');

        showTotalAforosAdded('selected_text', total_aforos_added[0]?.count);
        selected_container.classList.remove('d-none');

        if (
            !total_aforos_added ||
            total_aforos_added.length === 0 ||
            !total_aforos_added[0]?.count
        ) {
            // Ocultar el contenedor de selected_container d-none
            selected_container.classList.add('d-none');
            return total_aforos_added;
        }

        //todo Comportamiento deacuerdo a la ubicacion

        if ((ubication === 'dooh' && total_aforos_added[0]?.type === 'OOH') || (ubication === 'ooh' && total_aforos_added[0]?.type === 'DOOH')) {
            // Ocultar los add_spaces_btn
            add_spaces_btn.forEach((btn) => {
                btn.classList.add('d-none');
            });
        
            const result = await alertHtml(
                'Selección de aforos desactivada',
                `Ya tienes pre-seleccionados <strong>${total_aforos_added[0]?.count}</strong> aforos en la categoría <strong>${total_aforos_added[0]?.type}</strong> , unidad de negocio:  <strong>${total_aforos_added[0]?.business_unit}</strong> ; para seleccionar aforos de la categoría ${ubication.toUpperCase()} debes eliminar los aforos seleccionados.`,
                'Eliminar pre-selección',
                'Cerrar',
                'info',
                '#d33',
                '#0933f1'
            );
        
            // Imprimir en consola si le da click en eliminar aforos
            if (result.isConfirmed) {
                // Preguntar si desea eliminar los aforos
                const resultConfirm = await alertConfirm(
                    '¿Estás seguro?',
                    'Si aceptas, se eliminarán todos los aforos pre-seleccionados.',
                    'Eliminar pre-selección',
                    'Cancelar'
                );
        
                // Si está confirmado
                if (resultConfirm.isConfirmed) {
                    let srvstring = ubication === 'ooh' ? 'dooh' : 'ooh';
                    const res = await fetchData(`${window.location.origin}/aforo/delete-station-temp/${srvstring}`);
                    await alertGeneric(`${res?.status ? "Operación ejecutada correctamente" : 'Error al eliminar los aforos'}`, `${res?.status || 'Error al eliminar los aforos'}`, 2000, false, false, `${res?.status ? 'success' : 'error'}`);
        
                    // Recargar la página después de 2 segundos
                    setTimeout(() => {
                        window.location.reload();
                    }, 2000);
                }
            }
        
            return total_aforos_added;
        } 
    }

    return total_aforos_added;
};


//! Funcion que recarga la pagina con los filtros si no existen en la url
const reload_page_with_filters = (payload) => {
    // console.log(payload);
    if (payload.length === 0 || !payload[0]?.business_unit_id || !payload[0]?.initial_date || !payload[0]?.finish_date) {
        return;
    }

    // Destructurar el objeto data
    const data = payload[0];
    const business_unit_id = data.business_unit_id.toString();
    const initial_date = data.initial_date;
    const finish_date = data.finish_date;

    // Obtener los parámetros de la URL
    const urlParams = new URLSearchParams(window.location.search);

    // Desestructurar los parámetros de la URL
    const business_unit = urlParams.get('business_unit');
    const date_init = urlParams.get('date_init');
    const date_end = urlParams.get('date_end');

    // Si los parámetros de la URL no existen o son diferentes a los de la data recibida
    if (
        business_unit !== business_unit_id ||
        date_init !== initial_date ||
        date_end !== finish_date
    ) {
        // Actualizar o agregar manualmente los parámetros en la URL actual
        urlParams.set('business_unit', business_unit_id);
        urlParams.set('date_init', initial_date);
        urlParams.set('date_end', finish_date);

        // Redirigir a la nueva URL con los parámetros actualizados
        const newUrl = `${window.location.pathname}?${urlParams.toString()}`;
        window.location.href = newUrl;
    }


}


const aforosFunctions = async () => {
    let ubication = '';

    const aforos_container = document.getElementById('aforos_container');
    if (!aforos_container) return;

    const add_spaces_btn = aforos_container.querySelectorAll('#add_spaces_btn');
    const date_init = document.querySelector('#date_init');
    const date_end = document.querySelector('#date_end');


    const list_filter_container = document.querySelector('.list_filter_containers');

    const total_aforos_added = await updateTotalAforos(ubication);
    reload_page_with_filters(total_aforos_added);

    
    const type = total_aforos_added[0]?.type;

    const selected_container = document.querySelector('.selected_container');
    if (!selected_container) return;
    ubication = selected_container.getAttribute('data-section');

    selected_container.addEventListener('click', (e) => {
    let urlpath = ""
    type === 'OOH' ? urlpath = "/aforo/aforo-ooh/reserva/crear" : urlpath = "/aforo/aforo-dooh/reserva/crear"

        alertConfirm(
            '¿Ya finalizaste la selección de aforos?',
            'Si aceptas, pasaras a la ventana de creación de reservas.',
            'Continuar',
            'Cancelar'
        ).then(async (result) => {
            // redireccionar a la url
            if (result.isConfirmed) {
                // Redireccionar a la url
                window.location.href = urlpath;
            }
        });
    });

    add_spaces_btn.forEach((btn) => {
        // Añadir el estilo de cursor pointer
        btn.style.cursor = 'pointer';

        btn.addEventListener('click', async (e) => {
            e.preventDefault();

            if (
                !date_init.value ||
                !date_end.value ||
                date_init.value > date_end.value ||
                date_init.value === '' ||
                date_end.value === ''
            ) {
                alertError(
                    '¡Error!',
                    'Por favor, selecciona un rango de fechas válido.'
                );
                return;
            }

            const url = `${window.location.origin}${btn.getAttribute(
                'data-url'
            )}`; // URL del botón

            const userId = btn.getAttribute('data-user-id'); // ID del usuario
            const data_item_id = btn.getAttribute('data-item-id'); // ID del espacio
            const data_item_bussines_unit =
                btn.getAttribute('data-bussines-unit'); // Unidad de negocio

            const data_item_name = btn.getAttribute('data-item-name'); // Nombre del espacio
            const data_item_location = btn.getAttribute('data-item-location'); // Ubicación del espacio
            const data_item_station = btn.getAttribute('data-item-station'); // Localización del espacio
            const data_ocuppated_name = btn.getAttribute('data-ocuppated-name'); // Nombre de la reserva
            const data_ocuppated_initial_date = btn.getAttribute(
                'data-ocuppated-initial-date'
            ); // Fecha inicial de la reserva
            const data_ocuppated_finish_date = btn.getAttribute(
                'data-ocuppated-finish-date'
            ); // Fecha final de la reserva

            const option =
                ubication === 'ooh' ? 'capacity_ooh' : 'capacity_dooh';
                if (
                    data_ocuppated_name !== 'None' &&
                    data_ocuppated_initial_date !== 'None' &&
                    data_ocuppated_finish_date !== 'None'
                ) {
                    // console.log('url: ', url);
                
                    const result = await alertHtml(
                        '¡Este espacio ya está ocupado!',
                        ` <b>Este espacio ya está ocupado:</b><br> <b>Nombre:</b> ${data_ocuppated_name}<br> <b>Fecha inicial:</b> ${data_ocuppated_initial_date}<br> <b>Fecha final:</b> ${data_ocuppated_finish_date}<br><br> <b>Si aceptas, el siguiente espacio se agregará a la cola de reserva:</b><br> <b>Nombre:</b> ${data_item_name}<br> <b>Ubicación:</b> ${data_item_location}<br> <b>Localización:</b> ${data_item_station}<br> `,
                        'Agregar a la cola de reserva!',
                        'Cancelar'
                    );
                
                    if (result.isConfirmed) {
                        await registerAforo(url, {
                            user: userId,
                            [option]: data_item_id,
                            type_booking: data_item_bussines_unit,
                            initial_date: date_init.value,
                            finish_date: date_end.value,
                        });
                
                        // await updateTotalAforos(ubication);
                    }
                } else {
                    const result = await alertHtml(
                        '¿Estás seguro?',
                        ` <b>Estás a punto de agregar este espacio.</b><br> <b>Nombre:</b> ${data_item_name}<br> <b>Ubicación:</b> ${data_item_location}<br> <b>Localización:</b> ${data_item_station}<br> `,
                        'Agregar espacio!',
                        'Cancelar'
                    );
                
                    if (result.isConfirmed) {
                        // redireccionar a la url
                        // Si ubicacion es ooh, entonces se debe enviar  capacity_ooh: data_item_id y si es dooh capacity_dooh: data_item_id
                        await registerAforo(url, {
                            user: userId,
                            [option]: data_item_id,
                            type_booking: data_item_bussines_unit,
                            initial_date: date_init.value,
                            finish_date: date_end.value,
                        });
                
                        // await updateTotalAforos(ubication);
                    }
                }
        });
    });

    // Boton de limpiar filtros
    if (list_filter_container) {
        
        // Si esoy en la misma ubicacion de los aforos seleccionados
        if ((ubication === 'ooh' && total_aforos_added[0]?.type === 'OOH') || (ubication === 'dooh' && total_aforos_added[0]?.type === 'DOOH')) {
            if (total_aforos_added && total_aforos_added.length > 0 && total_aforos_added[0]?.count) {
                const alert_danger = document.querySelector('#alert_danger');
                alert_danger.classList.remove('d-none');
                //clean_filter_mobile.classList.add('d-none');
                const clean_filters_service_text = document.createElement('p');
                clean_filters_service_text.classList.add('mb-0');
                clean_filters_service_text.innerHTML = `Ya tienes aforos pre-seleccionados por lo tanto se activa el filtro automático - Categoria: <strong>${total_aforos_added[0]?.type}</strong> Unidad de negocio: <strong>${total_aforos_added[0]?.business_unit}</strong> , Fecha inicial: <strong>${total_aforos_added[0]?.initial_date}</strong> , Fecha final: <strong>${total_aforos_added[0]?.finish_date}</strong>  `;
                const clean_filters_service_span = document.createElement('span');
                clean_filters_service_span.style.cursor = 'pointer';
                clean_filters_service_span.style.color = '#0933f1';
                clean_filters_service_span.style.textDecoration = 'underline';
                clean_filters_service_span.innerHTML = 'Limpiar filtros y pre-selección';
                clean_filters_service_span.id = 'clean_filters_service';
                clean_filters_service_text.appendChild(clean_filters_service_span);
                alert_danger.appendChild(clean_filters_service_text);
    
                // Desactivar el d-none alert_danger
                // Ponerle el texto de "Ya tienes aforos seleccionados por lo tanto se activa el filtro de pre-selección"
                // alert_danger.innerHTML = `<p class="text-center">Ya tienes aforos seleccionados por lo tanto se activa el filtro de pre-selección</p>`;
    
                // Agregar el evento click al boton de limpiar filtros
                clean_filters_service_span.addEventListener('click', async (e) => {
                    e.preventDefault();
    
                    // Preguntar si desea eliminar los aforos
                    const resultConfirm = await alertConfirm(
                        '¿Estás seguro?',
                        'Si aceptas, se eliminarán todos los filtros y aforos pre-seleccionados.',
                        'Limpiar filtros',
                        'Cancelar'
                    );
    
                    // Si está confirmado
                    if (resultConfirm.isConfirmed) {
                        let srvstring = ubication === 'ooh' ? 'ooh' : 'dooh';
                        const res = await fetchData(`${window.location.origin}/aforo/delete-station-temp/${srvstring}`);
                        await alertGeneric(`${res?.status ? "Operación ejecutada correctamente" : 'Error al eliminar los aforos'}`, `${res?.status || 'Error al eliminar los aforos'}`, 2000, false, false, `${res?.status ? 'success' : 'error'}`);
            
                        // Recargar la página después de 2 segundos
                        setTimeout(() => {
                            window.location.href = `${window.location.origin}/aforo/aforo-${srvstring}/`;
                        }, 2000);
                    }
    
                    }
                );
                            
    
            }
        } else {
            if (total_aforos_added && total_aforos_added.length > 0 && total_aforos_added[0]?.count) {
                const alert_danger = document.querySelector('#alert_danger');
                alert_danger.classList.remove('d-none');
                //clean_filter_mobile.classList.add('d-none');
                const clean_filters_service_text = document.createElement('p');
                clean_filters_service_text.classList.add('mb-0');
                clean_filters_service_text.innerHTML = `Tienes aforos agregados en la categoría <strong>${total_aforos_added[0]?.type}</strong>. La opción de pre-selección estará deshabilitada hasta que elimines estos espacios.  `;
                const clean_filters_service_span = document.createElement('span');
                clean_filters_service_span.style.cursor = 'pointer';
                clean_filters_service_span.style.color = '#0933f1';
                clean_filters_service_span.style.textDecoration = 'underline';
                clean_filters_service_span.innerHTML = 'Eliminar pre-selección';
                clean_filters_service_span.id = 'clean_filters_service';
                clean_filters_service_text.appendChild(clean_filters_service_span);
                alert_danger.appendChild(clean_filters_service_text);
    
                // Desactivar el d-none alert_danger
                // Ponerle el texto de "Ya tienes aforos seleccionados por lo tanto se activa el filtro de pre-selección"
                // alert_danger.innerHTML = `<p class="text-center">Ya tienes aforos seleccionados por lo tanto se activa el filtro de pre-selección</p>`;
    
                // Agregar el evento click al boton de limpiar filtros
                clean_filters_service_span.addEventListener('click', async (e) => {
                    e.preventDefault();
    
                    // Preguntar si desea eliminar los aforos
                    const resultConfirm = await alertConfirm(
                        '¿Estás seguro?',
                        'Si aceptas, se eliminarán todos los filtros y aforos pre-seleccionados.',
                        'Limpiar filtros',
                        'Cancelar'
                    );
    
                    // Si está confirmado
                    if (resultConfirm.isConfirmed) {
                        let srvstring = ubication === 'ooh' ? 'dooh' : 'ooh';
                        const res = await fetchData(`${window.location.origin}/aforo/delete-station-temp/${srvstring}`);
                        await alertGeneric(`${res?.status ? "Operación ejecutada correctamente" : 'Error al eliminar los aforos'}`, `${res?.status || 'Error al eliminar los aforos'}`, 2000, false, false, `${res?.status ? 'success' : 'error'}`);
            
                        // Recargar la página después de 2 segundos
                        setTimeout(() => {
                            window.location.href = `${window.location.origin}/aforo/aforo-${srvstring === 'ooh' ? 'dooh' : 'ooh'}/`;
                        }, 2000);
                    }
    
                    }
                );
            }
        }

        
    }


    
    
};

export default aforosFunctions;
