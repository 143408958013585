import Chart from 'chart.js/auto'



const dashboardGraphics = () => {

    const chartOccupation = () => {
        
        const dashboard_container_main = document.querySelector('.dashboard_container_main');
        if (!dashboard_container_main) return;

        // Obtén los datos de la tabla HTML
        let table = document.querySelector('.table_occupation');
        if (!table) return;
    
        let ctx = document.getElementById('chartOccupation').getContext('2d');
        if (!ctx) return;

        let labels = [];
        let data = [];
    
        
        let rows = table.getElementsByTagName('tr');
    
        
        
        setTimeout(() => {
            for (let i = 1; i < rows.length; i++) {
                let cols = rows[i].getElementsByTagName('td');
                labels.push(cols[2].innerText); // Utiliza el nombre como etiqueta en el gráfico
                data.push(parseInt(cols[4].innerText)); // Utiliza la cantidad como dato en el gráfico
            }

            myChart.update();
        }, 500);
    
        let myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Cantidad por localización',
                    data: data,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });
    }

    const chartElements = () => {
        const dashboard_container_main = document.querySelector('.dashboard_container_main');
        if (!dashboard_container_main) return;

        // Obtén los datos de la tabla HTML
        let table = document.querySelector('.table_elements');
        if (!table) return;

        let ctx = document.getElementById('chartElements').getContext('2d');
        if (!ctx) return;

        let labels = [];
        let data = [];


        let rows = table.getElementsByTagName('tr');

        setTimeout(() => {
            for (let i = 1; i < rows.length; i++) {
                let cols = rows[i].getElementsByTagName('td');
                labels.push(cols[1].innerText); // Utiliza el elemento como etiqueta en el gráfico
                data.push(parseInt(cols[2].innerText)); // Utiliza la cantidad como dato en el gráfico
            }
            myChart.update();
        }, 500);

        let myChart = new Chart(ctx, {
            type: 'doughnut',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Cantidad por Elemento',
                    data: data,
                    backgroundColor: [
                        
                        'rgba(46, 255, 0, 0.2)',
                        'rgba(31, 173, 0, 0.2)',
                        'rgba(19, 107, 0, 0.2)',
                        'rgba(255, 242, 0, 0.2)',
                        'rgba(255, 187, 0, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                    ],
                    borderColor: [
                        'rgba(46, 255, 0, 1)',
                        'rgba(31, 173, 0, 1)',
                        'rgba(19, 107, 0, 0.5)',
                        'rgba(255, 242, 0, 1)',
                        'rgba(255, 187, 0, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    
                }
            }
        });
    };

    const chartTopCommercial = () => {
        const dashboard_container_main = document.querySelector('.dashboard_container_main');
        if (!dashboard_container_main) return;

        // Obtén los datos de la tabla HTML
        let table = document.querySelector('.table_top_commercial');
        if (!table) return;

        let ctx = document.getElementById('chartTopCommercial').getContext('2d');
        if (!ctx) return;

        let labels = [];
        let data = [];


        let rows = table.getElementsByTagName('tr');

        setTimeout(() => {
            for (let i = 1; i < rows.length; i++) {
                let cols = rows[i].getElementsByTagName('td');
                labels.push(`${cols[1].innerText}`); // Utiliza el nombre y apellido como etiqueta en el gráfico
                data.push(parseInt(cols[3].innerText)); // Utiliza la cantidad como dato en el gráfico
            }
            myChart.update();
        }, 500);

        let myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Cantidad por Comercial',
                    data: data,
                    backgroundColor: 'rgba(54, 162, 235, 0.2)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1
                }]
            },
            options: {
                indexAxis: 'y', // Asegúrate de especificar el eje para los gráficos horizontales
                responsive: true,
                scales: {
                    x: {
                        beginAtZero: true
                    }
                }
            }
        });
    };


    const chartCampaigns = () => {
        const dashboard_container_main = document.querySelector('.dashboard_container_main');
        if (!dashboard_container_main) return;

        // Obtén los datos de la tabla HTML
        let table = document.getElementById('table_campaigns');
        if (!table) return;
    
        let ctx = document.getElementById('chartCampaigns').getContext('2d');
        if (!ctx) return;
    
        let labels = [];
        let data = [];
    

        let rows = table.getElementsByTagName('tr');
    
        setTimeout(() => {
            for (let i = 1; i < rows.length; i++) {
                let cols = rows[i].getElementsByTagName('td');
                labels.push(`${cols[1].innerText}`); // Utiliza nombre, agencia y cliente como etiquetas en el gráfico
                data.push(parseInt(cols[4].innerText)); // Utiliza la cantidad como dato en el gráfico
            }
            myChart.update();
        }, 500);
    
        let myChart = new Chart(ctx, {
            type: 'pie',
            data: {
                labels: labels,
                datasets: [{
                    label: 'Cantidad por Item',
                    data: data,
                    backgroundColor: [
                        
                        'rgba(46, 255, 0, 0.2)',
                        'rgba(31, 173, 0, 0.2)',
                        'rgba(19, 107, 0, 0.2)',
                        'rgba(255, 242, 0, 0.2)',
                        'rgba(255, 187, 0, 0.2)',
                        'rgba(255, 159, 64, 0.2)',
                        'rgba(54, 162, 235, 0.2)',
                        'rgba(255, 206, 86, 0.2)',
                        'rgba(75, 192, 192, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                    ],
                    borderColor: [
                        'rgba(46, 255, 0, 1)',
                        'rgba(31, 173, 0, 0.5)',
                        'rgba(19, 107, 0, 0.5)',
                        'rgba(255, 242, 0, 1)',
                        'rgba(255, 187, 0, 1)',
                        'rgba(255, 159, 64, 1)',
                        'rgba(54, 162, 235, 1)',
                        'rgba(255, 206, 86, 1)',
                        'rgba(75, 192, 192, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                responsive: true,
            }
        });
    };


    return {
        chartOccupation,
        chartElements,
        chartTopCommercial,
        chartCampaigns
    }
};

export default dashboardGraphics;