const userNameToggler = () => {
    const profileItemsContainer = document.querySelector('.profile_items_container');

    if (profileItemsContainer) {
        const img_chevron = document.querySelector('.img_chevron');
        const profile_links = document.querySelector('.profile_links');


        if( img_chevron ) {
            img_chevron.addEventListener('click', () => {
                profile_links.classList.toggle('d-none');
                img_chevron.classList.toggle('rotate_chevron');
            });
        }

    }
}

export default userNameToggler;