import { alertError } from './helpers/swalAlerts.js';
import { clearErrorClasses, addErrorClasses } from './helpers/validateInputsColor.js';
import { populateSelect } from './helpers/populateSelect.js';
import { fetchData } from './helpers/fetchData.js';
import { validateDate } from './helpers/validateDate.js';

const dashboardFunctions = () => {

  const getReport = async (reportTypeName, businessUnitId, typeServiceId, sinceDate, untilDate) => {
    const url = `${window.location.origin}/aforo/${reportTypeName}/${businessUnitId}/${typeServiceId}/${sinceDate}/${untilDate}`;
    return await fetchData(url);
  };

  const downloadReport = async (reportTypeName, businessUnitId, typeServiceId, sinceDate, untilDate) => {
    const url = `${window.location.origin}/aforo/${reportTypeName}/${businessUnitId}/${typeServiceId}/${sinceDate}/${untilDate}/excel`;
    window.open(url, '_blank');
  };

  const handleReport = async (reportType, containerId, data) => {
    const reportContainer = document.querySelector(`#${containerId}`);
    const businessUnitSelect = reportContainer.querySelector('#business_unit_select');
    const serviceTypeSelect = reportContainer.querySelector('#service_type_select');
    const sinceDateInput = reportContainer.querySelector('#since_date');
    const untilDateInput = reportContainer.querySelector('#until_date');
    const submitBtn = reportContainer.querySelector(`#submit_${reportType}`);
    const downloadBtn = reportContainer.querySelector(`#download_${reportType}`);
    const ldsRoller = document.querySelector(`#lds_roller_${reportType}`);
    const noDataContainer = document.querySelector(`#no_data_${reportType}`);

    serviceTypeSelect.disabled = true;
    serviceTypeSelect.style.backgroundColor = '#f2f2f2';

    let reportName = '';

    // Mapear el reportType al nombre correspondiente
    const reportTypeToName = {
      'first_report': 'occupation-point',
      'second_report': 'typology',
      'third_report': 'commercial',
    };

    // Validar que reportType esté en el mapeo antes de asignar el nombre
    if (reportType in reportTypeToName) {
        reportName = reportTypeToName[reportType];
    } else {
        console.error(`Report type '${reportType}' not recognized.`);
        return;
    }

    const getBusinessUnits = async () => {
      const url = `${window.location.origin}/aforo/get-business-unit`;
      const businessUnits = await fetchData(url);
      const businessUnitsConverted = businessUnits?.business_unit_list?.map(businessUnit => ({
        value: businessUnit.pk,
        text: businessUnit.name
      }));
      populateSelect(businessUnitSelect, businessUnitsConverted);
    };

    getBusinessUnits();

    businessUnitSelect.addEventListener('change', async (e) => {
      const businessUnitId = e.target.value;

      if (businessUnitId !== '') {
        serviceTypeSelect.disabled = false;
        serviceTypeSelect.style.backgroundColor = '#fff';

        const getTypesOfServices = async (businessUnitId) => {
          const url = `${window.location.origin}/aforo/get-type-service/${businessUnitId}`;
          const typesOfServices = await fetchData(url);
          const typesOfServicesConverted = typesOfServices?.type_service_list.map(typeService => ({
            value: typeService.pk,
            text: typeService.name
          }));

          populateSelect(serviceTypeSelect, typesOfServicesConverted);
        };

        getTypesOfServices(businessUnitId);
      } else {
        serviceTypeSelect.disabled = true;
        serviceTypeSelect.style.backgroundColor = '#f2f2f2';
        serviceTypeSelect.innerHTML = `<option value="">----------</option>`;
      }
    });

    submitBtn.addEventListener('click', async (e) => {
      e.preventDefault();
      clearErrorClasses([businessUnitSelect, serviceTypeSelect, sinceDateInput, untilDateInput]);

      if (!validateDate(sinceDateInput, untilDateInput)) return;

      if (addErrorClasses(businessUnitSelect, serviceTypeSelect, sinceDateInput, untilDateInput)) {
        const reportTable = document.querySelector(`#${reportType}_table`);
        reportTable.querySelector('tbody').innerHTML = '';

        noDataContainer.classList.add('d-none');
        ldsRoller.classList.remove('d-none');
        ldsRoller.classList.add('d-block');

        await new Promise(resolve => setTimeout(resolve, 1500));

        const reportData = await getReport(reportName, businessUnitSelect.value, serviceTypeSelect.value, Date.parse(sinceDateInput.value), Date.parse(untilDateInput.value));
        


        //! ********************* DATA FAKE ********************* */
        // Asumiendo que reportData tiene al menos una fila para determinar la estructura
        const count = 0; // Cantidad de datos falsos a generar
        const columnCount = reportData[0].length;

        reportData.push(...Array.from({ length: count }, (_, i) => {
        // Generar datos falsos con la misma cantidad de columnas que la estructura real
        const fakeData = Array.from({ length: columnCount }, (_, j) => {
            if (j < 3) {
            return `FAKE Data ${i}`;
            } else {
            return 0;
            }
        });

        return fakeData;
        }));

        //! ********************* DATA FAKE ********************* */


        const filteredArray = reportData.slice(1);
        // console.log(filteredArray);

        ldsRoller.classList.remove('d-block');
        ldsRoller.classList.add('d-none');

        if (filteredArray.length === 0) {
          noDataContainer.classList.add('d-block');
          noDataContainer.classList.remove('d-none');
          return;
        }

        filteredArray.forEach((element) => {
            const tr = document.createElement('tr');
          
            const createCell = (value) => {
              const td = document.createElement('td');
              td.textContent = value;
              return td;
            };
          
            element.forEach((col) => {
              tr.appendChild(createCell(col));
            });
          
            reportTable.querySelector('tbody').appendChild(tr);
          });
      } else {
        alertError('¡Error!', 'Debes llenar todos los campos', 2000, false);
      }
    });

    downloadBtn.addEventListener('click', async (e) => {
      e.preventDefault();

      clearErrorClasses([businessUnitSelect, serviceTypeSelect, sinceDateInput, untilDateInput]);

      if (!validateDate(sinceDateInput, untilDateInput)) return;

      if (addErrorClasses(businessUnitSelect, serviceTypeSelect, sinceDateInput, untilDateInput)) {
        await downloadReport(reportName, businessUnitSelect.value, serviceTypeSelect.value, Date.parse(sinceDateInput.value), Date.parse(untilDateInput.value));
      } else {
        alertError('¡Error!', 'Debes llenar todos los campos', 2000, false);
      }
    });
  };

  // Validar que estamos en la página de dashboard
  if (window.location.pathname !== '/aforo/dashboard-aforo/') return;

  // Aplique la función handleReport a cada reporte
  handleReport('first_report', 'first_report', 'first_report_data');
  handleReport('second_report', 'second_report', 'second_report_data');
  handleReport('third_report', 'third_report', 'third_report_data');
};

export default dashboardFunctions;
